/**
 * Equity Quote Indicator (Quote Condition)
 *
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */

export enum QMEquityQuoteIndicator {
  /**
   * Revise Quote (OTC Markets)
   */
  REVISE_QUOTE = '1',
  /**
   * New Quote (OTC Markets)
   */
  NEW_QUOTE = '2',
  /**
   * Withdraw Quote (OTC Markets)
   */
  WITHDRAW_QUOTE = '3',
  /**
   * Depth on Ask side (Manual Ask, automated Bid ­ Nasdaq)
   * ---
   * This indicates that the market participant’s Ask is in a manual mode.
   * While in this mode, automated execution is not eligible on the Ask side and can be traded through pursuant to Regulation NMS requirements.
   */
  DEPTH_ON_ASK_SIDE = 'A',
  /**
   * Depth on Bid side (Manual Bid, automated Ask ­ Nasdaq)
   * ---
   * This indicates that the market participant’s Bid is in a manual mode.
   * While in this mode, automated execution is not eligible on the Bid side and can be traded through pursuant to Regulation NMS requirements.
   */
  DEPTH_ON_BID_SIDE = 'B',
  /**
   * This condition is disseminated by the UTP participant to signify either the last quote of the day or the premature close for the day.
   * In the latter case, the UTP participant may re-open the quote by subsequently submitting a quotation with a Regular Open Quote Condition.
   */
  CLOSING_QUOTE = 'C',
  /**
   * A regulatory halt used when relevant news influencing the stock is disseminated.
   * Trading is suspended until the resultant impact has been assessed.
   */
  NEWS_DISSEMINATION = 'D',
  /**
   * A halt condition used when there is a sudden order influx.
   * To prevent a disorderly market, trading is temporarily suspended by the UTP participant.
   */
  ORDER_INFLUX = 'E',
  /**
   * For extremely active periods of short duration.
   * While in this mode,  the UTP participant will enter quotations on a “best efforts” basis.
   */
  FAST_TRADING = 'F',
  /**
   * Indicates the probable trading range (bid and offer sizes, no prices) of a security that is not opening delayed or halted.
   */
  TRADING_RANGE_INDICATION = 'G',
  /**
   * Depth on Bid and Ask (Manual Bid and Ask)
   * ---
   * This indicates that the market participant is in a manual mode on both the Bid and Ask.
   * While in this mode, automated execution is not eligible on the Bid and Ask side and can be traded through pursuant to Regulation NMS requirements.
   */
  DEPTH_ON_BID_AND_ASK = 'H',
  /**
   * A non-regulatory halt used when there is a severe buy or sell order imbalance. To prevent a disorderly market, trading is temporarily suspended.
   */
  ORDER_IMBALANCE = 'I',
  /**
   * Due to Related Security, News Dissemination
   * ---
   * A regulatory halt used when news relating to one security will affect trading in an associated security.
   * For example, news pertaining to the common issues of a company may affect the preferred issues.
   */
  SECURITY_NEWS_DISSEMINATION = 'J',
  /**
   * Due To Related Security, News Pending
   * ---
   * Same as `SECURITY_NEWS_DISSEMINATION` (see above) but refers to expected news.
   */
  SECURITY_NEWS_PENDING = 'K',
  /**
   * Closed Market Maker (NASDAQ)
   * ---
   * This condition is disseminated by each market maker to signify either the last quote of the day or the premature close of a market maker for the day.
   * In the latter case, the market maker can re-open by transmitting a quote accompanied by condition R.
   */
  CLOSED_MARKET_MAKER = 'L',
  /**
   * No eligible Market Participant Quotes in Issue at Market Close
   */
  NO_ELIGIBLE_QUOTE_AT_MARKET_CLOSE = 'M',
  /**
   * A regulatory halt used when the level of trading activity in a security is such that the Exchange cannot collect, process, and disseminate quotes that accurately reflect market conditions.
   * The specialist, with Floor Official approval, may switch to "non-firm mode" for 30 minutes.
   */
  NON_FIRM_QUOTE = 'N',
  /**
   * The first quote from a given UTP participant for a security.
   */
  OPENING_QUOTE = 'O',
  /**
   * Indicates a regulatory trading halt or delayed opening due to an expected news announcement which may influence trading in the stock.
   * A trading halt or opening delay may be reversed once the news has been disseminated.
   */
  NEWS_PENDING = 'P',
  /**
   * Additional Information, Due To Related Security
   * ---
   * A regulatory halt or delayed opening used if more disclosure of information for the security is requested for a related security.
   */
  SECURITY_ADDITIONAL_INFORMATION = 'Q',
  /**
   * Regular (NASDAQ Open)
   * ---
   * Indicates a normal trading environment where the UTP participant is showing interest on both sides of the market.
   */
  REGULAR = 'R',
  /**
   * Due To Related Security
   * ---
   * A non-regulatory halt used when events relating to one security will affect the price and performance of another security (e.g.,a tender offer).
   */
  SECURITY = 'S',
  /**
   * Used with zero-filled bid and offer sized to indicate that quoting is no longer suspended for a security that was halted or delayed.
   */
  RESUME = 'T',
  /**
   * Slow Quote Due to a NYSE Liquidity Replenishment Point (LRP), NYSE American, Tolerance Breach (Spread, Momentum or Gap Trade Tolerance)
   * ---
   * This condition indicates that the quote is a “Manual” quote on both the Bid and Offer sides due to a NYSE LRP, Amex Tolerance Breach or Gap Quote.
   * While in this mode, auto-execution is not eligible, the quote is then considered Manual and Non-Firm in the Bid and Offer and either or both sides can be traded through as per Regulation NMS.
   *
   * OR
   *
   * Gap Quote on Both the Bid and Offer Sides (Manual Bid and Ask (non­firm) ­ Nasdaq)
   * ---
   * This indicates that the market participant is in a manual mode on both the Bid and Ask, and is conducting an auction.
   * While in this mode, automated execution is not eligible on the Bid and Ask side and the UTP participant has suspended the firm quote obligation for the reported security.
   */
  SLOW_OR_GAP_QUOTE = 'U',
  /**
   * In View of Common
   * ---
   * A non-regulatory trading halt used when matters that affect the common stock of a company may affect the performance of other classes of securities for the same company.
   */
  IN_VIEW_OF_COMMON = 'V',
  /**
   * Slow Quote Due to Set Slow List on Both the Bid and Offer Sides
   * ---
   * This condition indicates that the current bid is in “Slow” quote mode due to a NYSE LRP or Gap Quote.
   * While in this mode, auto-execution is not eligible the quote is then considered Slow on the Bid side and can be traded through as per Regulation NMS.
   */
  SET_SLOW_LIST_ON_BOTH_SIDES = 'W',
  /**
   * Indicates a non-regulatory trading halt used when trading in a particular security is temporarily inhibited due to a systems, equipment or communications facility problem or for other technical reasons.
   */
  EQUIPMENT_CHANGEOVER = 'X',
  /**
   * Indicates a normal trading environment where the UTP participant is showing interest on only one side of the market (Bid or Ask).
   */
  REGULAR_ONE_SIDED = 'Y',
  /**
   * No Open/No Resume
   * ---
   * If the UTP participant has a trading halt or an opening delay that is to be in effect for the rest of the trading day, this condition will be marked.
   */
  NO_OPEN_RESUME = 'Z',
  /**
   * No Special condition exists
   */
  NO_CONDITION = ' '
}
