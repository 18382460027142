/**
 * RegSHO Status
 * ---
 * Regulation SHO is a set of rules from the SEC that governs short sale practices.
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 * @see https://www.investopedia.com/terms/s/shortsale.asp
 */

export enum QMRegSHOStatus {
  NOT_RESTRICTED = 'N',
  RESTRICTION_STARTED_TODAY = 'R',
  /**
   * Restriction carried over from prior day
   */
  RESTRICTION_CARRIED_OVER = 'r',
  UNKNOWN = '-'
}
