/**
 * Differentiates between the different feed types from QuoteMedia.
 */
export enum QMFeedType {
  Data = 0, // CDF
  LastSale, // CLS
  BidAndOffer // CBBO
}

/**
 * As described in the Appendix A: List of Data Channels
 * 
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022167
 */
export interface QMDataChannel {
  /**
   * Also called the Service Name
   */
  feed: string;
  name: string;
  feedType: QMFeedType;
  exchanges: string[];
  description: string;

  /**
   * The date from which we have data for this channel.
   */
  startDate: Date;
  notes: string;
  /**
   * QM Admin Client Package Code (Internal Use Only)
   */
  internalCodes: string[];
  includesMoreThanTrades?: any[],
}


