import { configureStore } from "@reduxjs/toolkit";
// import { ThunkAction, Action } from "@reduxjs/toolkit";

import { timeTravelReducer as timeTravel } from "../pages/timeTravel/TTSlice";
import { TOBReducer as TOB } from "../pages/TOB/TOBSlice";
import { sentimentReducer as sentiment } from "../pages/sentiment/sentimentSlice";
import { reportsReducer as reports } from "../pages/reports/reportsSlice";
import { downloadsReducer as downloads } from "../pages/downloads/downloadsSlice";
import { domainReducer as domain } from "../pages/domain/domainSlice";
import { loginReducer as loginDetails } from "../pages/login/loginSlice";

export const store = configureStore({
  reducer: {
    timeTravel,
    TOB,
    sentiment,
    reports,
    downloads,
    domain,
    loginDetails,
  },
});

// Infer the 'RootState' and 'AppDispatch' types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;
