import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { format } from "date-fns";
import { xxhash64_ObjectToUniqueStringNoWhiteSpace } from "../common/utils";
import {
  QMLevel2Event,
  QMVenueShort,
} from "@berkindale/berkindale-provider-quotemedia-domain";
import {
  QMEventsInput,
  qmFetchEvents,
  qmFetchOrderBook,
} from "@berkindale/berkindale-provider-quotemedia-javascript-api";
import { AppDispatch, RootState } from "../../app/store";
import { getPreviousDay } from "../common/utils";

const previousTradingDay = getPreviousDay(new Date(), true, false).setHours(
  9,
  30,
  1,
  0
);

export interface EventsDataState {
  [index: number]: QMLevel2Event[];
}

export interface EventsState {
  eventsData: EventsDataState;
  hash: number | undefined;
  loading: boolean;
  error: string | null;
  empty: boolean;
}

export interface EventFilters {
  orderId: string;
  brokerId: string;
  msgType: string;
}

export interface BidOrAskCreatedFromSingleQueueRecord {
  side: string;
  price: number;
  size: number;
  numOrders: number;
  qty?: number;
}
export interface OrdersOrDepthObjectContainingArraysOfBidsAndAsks {
  bids: BidOrAskCreatedFromSingleQueueRecord[];
  asks: BidOrAskCreatedFromSingleQueueRecord[];
}
export interface OrderBookState {
  orders: OrdersOrDepthObjectContainingArraysOfBidsAndAsks;
  depth: OrdersOrDepthObjectContainingArraysOfBidsAndAsks;
  loading: boolean;
  error: string | null;
}

export interface TTFormData {
  ticker: string;
  venue: QMVenueShort;
  date: number;
}

export interface TimeTravelState {
  events: EventsState;
  activeRow: {
    rowId: number;
    event: QMLevel2Event | null;
  };
  activeBkSequenceNumber: number | null;
  eventFilters: EventFilters;
  orderBook: OrderBookState;
  ttFormData: TTFormData;
}

const initialState: TimeTravelState = {
  events: {
    eventsData: {},
    hash: undefined,
    loading: false,
    error: null,
    empty: true,
  },
  activeRow: {
    rowId: 0,
    event: null,
  },
  activeBkSequenceNumber: null,
  eventFilters: {
    orderId: "",
    brokerId: "",
    msgType: "",
  },
  orderBook: {
    orders: { bids: [], asks: [] },
    depth: { bids: [], asks: [] },
    loading: false,
    error: null,
  },
  ttFormData: {
    ticker: "",
    venue: {
      code: "TSX",
      name: "TSX",
      suffix: "CA.TSX",
      earliestDate: "2022-06-13",
      category: "Lit Pools",
    },
    date: previousTradingDay,
  },
};

export interface FetchEventsInput {
  ticker: string;
  venue: QMVenueShort;
  from: number;
  jwtToken: string;
}

export type FetchEventsResponse = [number, QMLevel2Event[]];

export const fetchEvents = createAsyncThunk<
  // Return type of the payload creator
  FetchEventsResponse,
  // First argument to the payload creator
  FetchEventsInput,
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("timeTravel/fetchEvents", async (fetchEventsInput, thunkAPI) => {
  try {
    const { ticker, venue, from, jwtToken } = fetchEventsInput;
    const symbol: string = ticker;
    const venueSuffix: string = venue.suffix;
    console.log("TTSLice->venue", venue);
    // const symbol = ticker + ":" + venueSuffix;
    const date = format(from, "yyyy-MM-dd");
    const input: QMEventsInput = {
      symbol,
      venueSuffix,
      date,
      from,
      jwtToken,
    };
    const hash = xxhash64_ObjectToUniqueStringNoWhiteSpace({
      ticker: ticker,
      venue: venue,
      from,
    });
    const state: TimeTravelState = thunkAPI.getState().timeTravel;
    // if we already have the eventsData from a previous API call then return this array of hash and eventsData
    if (state.events.eventsData[hash] !== undefined) {
      return [hash, state.events.eventsData[hash]];
    }
    let resp = await qmFetchEvents(input);
    console.log("full response for qmFetchEvents", resp);
    resp = resp.sort(
      (a: QMLevel2Event, b: QMLevel2Event) =>
        a.bkSequenceNumber - b.bkSequenceNumber
    );
    /// harddcode tz offset hours as downstream charts display in UTC
    // 1. DavidS method:
    // const tzOffsetMin = new Date().getTimezoneOffset();
    // console.log("tzOffsetMin tCosts: ", tzOffsetMin);
    // 2 date-fns-tz method:
    // resp = resp.map((row) => {
    //   console.log(
    //     "utcToZonedTime:",
    //     utcToZonedTime(row.timestampAt, "America/New_York")
    //   );
    //   return {
    //     ...row,
    //     timestampAt: utcToZonedTime(
    //       row.timestampAt,
    //       "America/New_York"
    //     ).getTime(),
    //   };
    // });
    return [hash, resp] as FetchEventsResponse;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export interface FetchOrderBookInput {
  ticker: string;
  venue: QMVenueShort;
  date: number;
  untilBkSequence: number | null;
  jwtToken: string;
}

export interface FetchOrderBookResponse {
  orders: OrdersOrDepthObjectContainingArraysOfBidsAndAsks;
  depth: OrdersOrDepthObjectContainingArraysOfBidsAndAsks;
}

export const fetchOrderBook = createAsyncThunk<
  // Return type of the payload creator
  FetchOrderBookResponse,
  // First argument of the payload creator,
  FetchOrderBookInput,
  // Optional fields for defining thunkApi field types
  {
    state: RootState;
  }
>("timeTravel/fetchOrderBook", async (fetchOrderBookInput, thunkAPI) => {
  // this prevents calls with 'null' value to qmFetchOrderBook() in the javascript-api package.
  if (fetchOrderBookInput.untilBkSequence === null) {
    return thunkAPI.rejectWithValue("untilBkSequence is null.");
  }
  try {
    const DEPTHLEVELS = 10;
    const { ticker, venue, date, untilBkSequence, jwtToken } =
      fetchOrderBookInput;
    const venueSuffix = venue.suffix;
    const symbol = ticker;
    const dateObj = format(date, "yyyy-MM-dd");
    console.log("dateObj", dateObj);
    const input = {
      symbol,
      venueSuffix,
      date: dateObj,
      untilBkSequence,
      jwtToken,
    };
    let queues = await qmFetchOrderBook(input);
    let newQueues: BidOrAskCreatedFromSingleQueueRecord[] = queues
      .map((queue): BidOrAskCreatedFromSingleQueueRecord => {
        return {
          side: queue.side,
          price: queue.price,
          size: queue.size,
          numOrders: queue.numOrders,
        };
      });

    const bids = newQueues
      .filter((queue) => queue.side === "B")
      .sort((a, b) => b.price - a.price)
      .slice(0, DEPTHLEVELS)
      .sort((a, b) => a.price - b.price);

    const asks = newQueues
      .filter((queue) => queue.side === "S")
      .sort((a, b) => a.price - b.price)
      .slice(0, DEPTHLEVELS);

    const orders: OrdersOrDepthObjectContainingArraysOfBidsAndAsks = {
      bids,
      asks,
    };

    const depth: OrdersOrDepthObjectContainingArraysOfBidsAndAsks = {
      bids: [],
      asks: [],
    };
    let sum = 0;
    depth.bids = [...orders.bids]
      .sort((a, b) => b.price - a.price)
      .map((el) => {
        sum += el.size;
        const qty = sum;
        return { qty, ...el };
      });
    sum = 0;
    depth.asks = [...orders.asks]
      .sort((a, b) => a.price - b.price)
      .map((el) => {
        sum += el.size;
        const qty = sum;
        return { qty, ...el };
      });
    console.log("orders.bids[0]", orders.bids[0]);
    console.log("orders.asks[0]", orders.asks[0]);
    console.log("depth.bids[0]", depth.bids[0]);
    console.log("depth.asks[0]", depth.asks[0]);

    return { orders, depth } as FetchOrderBookResponse;
  } catch (error) {
    console.log("fetchOrderBook ThunkAPI error", error);
    return thunkAPI.rejectWithValue(error);
  }
});

// SLICE
export const timeTravelSlice = createSlice({
  name: "timeTravel",
  initialState,
  reducers: {
    // List of places where activeRow is updated:
    // TTControls.tsx-> prev and next event buttons -> handleActiveRowChange -> dispatch updateActiveRow;
    // EventTable.tsx-> handleRowClick -> handleActiveRowChange -> dispatch updateActiveRow;
    // TTControls.tsx -> event filters -> handleEventFiltersChange -> dispatch updateEventFilters;
    // fetchEvent.fulfilled (state.activeRow = 0).
    updateActiveRow: (state, action) => {
      state.activeRow.rowId = action.payload.rowId;
      state.activeRow.event = action.payload.event;
      state.activeBkSequenceNumber = action.payload.event.bkSequenceNumber;
    },
    updateActiveBkSequenceNumber: (state, action) => {
      state.activeBkSequenceNumber = action.payload.activeBkSequenceNumber;
    },
    updateEventFilters: (state, action) => {
      const { orderId, brokerId, msgType } = action.payload;
      state.eventFilters.orderId = orderId;
      state.eventFilters.brokerId = brokerId;
      state.eventFilters.msgType = msgType;
      // We have to calculate the filteredEvents here (stored in tmpEvents) so we can set the proper state.activeRow and state.activeBkSequenceNumber in this reducer.
      let tmpEvents: QMLevel2Event[] = (
        state.events.eventsData[state.events.hash!] || []
      ).map((el: QMLevel2Event) => {
        return { ...el };
      });
      if (msgType) {
        tmpEvents = (state.events.eventsData[state.events.hash!] || []).filter(
          (evt: QMLevel2Event) => {
            return evt.messageType === msgType;
          }
        );
      }
      if (orderId) {
        tmpEvents = tmpEvents.filter(
          (evt: QMLevel2Event) =>
            ("orderId" in evt &&
              evt.orderId &&
              evt.orderId.toLowerCase().startsWith(orderId.toLowerCase())) ||
            ("orderId" in evt &&
              evt.orderId &&
              evt.orderId.toLowerCase().startsWith(orderId.toLowerCase())) ||
            ("newOrderId" in evt &&
              evt.newOrderId &&
              evt.newOrderId.toLowerCase().startsWith(orderId.toLowerCase()))
        );
      }
      if (brokerId) {
        tmpEvents = tmpEvents.filter(
          (evt: QMLevel2Event) =>
            "marketMakerId" in evt &&
            evt.marketMakerId &&
            evt.marketMakerId.toLowerCase().startsWith(brokerId)
          // The properties 'sellerMmid' and 'buyerMmid' are from the old Athena table and are no longer used.
          // ||
          // ('sellerMmid' in evt && evt.sellerMmid &&
          //  evt.sellerMmid.toLowerCase().startsWith(brokerId)) ||
          // ('buyerMmid' in evt && evt.buyerMmid && evt.buyerMmid.toLowerCase().startsWith(brokerId))
        );
      }
      console.log("TMPEVENTS: ", tmpEvents);
      if (tmpEvents.length > 0) {
        const eventIndex: number = tmpEvents.findIndex(
          (event: QMLevel2Event) =>
            event.bkSequenceNumber === state.activeRow.event?.bkSequenceNumber
        );
        console.log("EVENTINDEX:", eventIndex);
        if (eventIndex !== -1) {
          state.activeRow = {
            event: state.activeRow.event!,
            rowId: eventIndex,
          };
        } else {
          state.activeRow = {
            rowId: 0,
            event: tmpEvents[0],
          };
        }
        state.activeBkSequenceNumber =
          tmpEvents[eventIndex !== -1 ? eventIndex : 0].bkSequenceNumber;
      }
    },
    updateTTFormData: (state, action) => {
      state.ttFormData.ticker = action.payload.ticker;
      state.ttFormData.venue = action.payload.venue;
      state.ttFormData.date = action.payload.date;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.events.loading = true;
        state.events.error = null;
        state.events.empty = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        const [hash, resp] = action.payload;
        state.events.eventsData[hash] = resp;
        state.events.hash = hash;
        state.events.loading = false;
        state.events.error = null;
        if (resp.length > 0) {
          state.events.empty = false;
          state.activeRow = { rowId: 0, event: resp[0] };
          state.activeBkSequenceNumber = resp[0].bkSequenceNumber;
        } else {
          state.events.empty = true;
          state.activeBkSequenceNumber = null;
          state.orderBook = {
            orders: { bids: [], asks: [] },
            depth: { bids: [], asks: [] },
            loading: false,
            error: null,
          };
        }
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.events.loading = false;
        state.events.error = action.error.message!;
        state.events.empty = true;
        state.activeBkSequenceNumber = null;
        state.orderBook = {
          orders: { bids: [], asks: [] },
          depth: { bids: [], asks: [] },
          loading: false,
          error: null,
        };
      });
    builder
      .addCase(fetchOrderBook.pending, (state) => {
        state.orderBook.loading = true;
        state.orderBook.error = null;
      })
      .addCase(fetchOrderBook.fulfilled, (state, action) => {
        state.orderBook.orders = action.payload.orders;
        state.orderBook.depth = action.payload.depth;
        state.orderBook.loading = false;
        state.orderBook.error = null;
      })
      .addCase(fetchOrderBook.rejected, (state, action) => {
        state.orderBook.loading = false;
        state.orderBook.error = action.error.message!;
      });
  },
});

// SELECTORS
export const selectEvents = (state: RootState) =>
  state.timeTravel.events.eventsData;
export const selectEventsLoading = (state: RootState) =>
  state.timeTravel.events.loading;
export const selectEventsError = (state: RootState) =>
  state.timeTravel.events.error;
export const selectEventsEmpty = (state: RootState) =>
  state.timeTravel.events.empty;
export const selectHash = (state: RootState) => state.timeTravel.events.hash;
export const selectActiveRow = (state: RootState) => state.timeTravel.activeRow;
export const selectActiveBkSequenceNumber = (state: RootState) =>
  state.timeTravel.activeBkSequenceNumber;
export const selectEventFilters = (state: RootState) =>
  state.timeTravel.eventFilters;
export const selectOrderBook = (state: RootState) => {
  return state.timeTravel.orderBook;
};
export const selectOrderBookLoading = (state: RootState) => {
  return state.timeTravel.orderBook.loading;
};
export const selectTTFormData = (state: RootState) => {
  return state.timeTravel.ttFormData;
};

// ACTION CREATORS
export const {
  updateActiveRow,
  updateActiveBkSequenceNumber,
  updateEventFilters,
  updateTTFormData,
} = timeTravelSlice.actions;

// SLICE REDUCER
export const timeTravelReducer = timeTravelSlice.reducer;

// MEMOIZED SELECTOR
export const selectFilteredEvents = createSelector(
  [selectEvents, selectHash, selectEventFilters],
  (eventsData, hash, filterData) => {
    const { orderId, brokerId, msgType } = filterData;
    let tmpEvents = (eventsData[hash!] || []).map((el: QMLevel2Event) => {
      return { ...el };
    });
    if (msgType) {
      tmpEvents = (eventsData[hash!] || []).filter((evt: QMLevel2Event) => {
        return evt.messageType === msgType;
      });
    }
    if (orderId) {
      tmpEvents = tmpEvents.filter(
        (evt: QMLevel2Event) =>
          ("orderId" in evt &&
            evt.orderId &&
            evt.orderId.toLowerCase().startsWith(orderId.toLowerCase())) ||
          ("orderId" in evt &&
            evt.orderId &&
            evt.orderId.toLowerCase().startsWith(orderId.toLowerCase())) ||
          ("newOrderId" in evt &&
            evt.newOrderId &&
            evt.newOrderId.toLowerCase().startsWith(orderId.toLowerCase()))
      );
    }
    if (brokerId) {
      tmpEvents = tmpEvents.filter(
        (evt: QMLevel2Event) =>
          "marketMakerId" in evt &&
          evt.marketMakerId &&
          evt.marketMakerId.toLowerCase().startsWith(brokerId)
        // The properties 'sellerMmid' and 'buyerMmid' are from the old Athena table and are no longer used.
        // ||
        // ('sellerMmid' in evt && evt.sellerMmid &&
        //  evt.sellerMmid.toLowerCase().startsWith(brokerId)) ||
        // ('buyerMmid' in evt && evt.buyerMmid && evt.buyerMmid.toLowerCase().startsWith(brokerId))
      );
    }
    return tmpEvents || [];
  }
);
