/**
 * Trade Indicator
 * ---
 * Trade Indicators reflect a Sale Condition used to indicate the type of trade transaction entered by a participant.
 * You may use this field to determine when to update the high, low, and last sale prices and volume for an issue.
 *
 * Trade Indicator descriptions are included in our applications.
 * This list is updated periodically.
 *
 * Some exchanges do not provide indicators for regular and off-book trades
 * Therefore, trade indicators are expected to sometimes not be included in data output.
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000020863-trade-indicators
 */


export type QMTradeIndicator = QMTradeIndicatorNamespace.NYSE | QMTradeIndicatorNamespace.NASDAQ | QMTradeIndicatorNamespace.Montreal | QMTradeIndicatorNamespace.CanadianEquity | QMTradeIndicatorNamespace.CME | QMTradeIndicatorNamespace.LSE;

export namespace QMTradeIndicatorNamespace {
  /**
   * Trade Indicator (NYSE)
   */
  export enum NYSE {
    /**
     * Regular Sale (Arca, American, National, Chicago and NYSE)
     */
    REGULAR_SALE = '@',
    AVERAGE_PRICE_TRADE = 'B',
    /**
     * Cash Trade (Same Day Clearing)
     */
    CASH_TRADE = 'C',
    DISTRIBUTION = 'D',
    AUTOMATIC_EXECUTION = 'E',
    INTERMARKET_SWEEP_ORDER = 'F',
    BUNCHED_SOLD_TRADE = 'G',
    PRICE_VARIATION_TRADE = 'H',
    ODD_LOT_TRADE = 'I',
    /**
     * Rule 127 (NYSE only) or Rule 155 (NYSE MKT only)
     */
    RULE_127_OR_155 = 'K',
    /**
     * Sold Last (Late Reporting)
     */
    SOLD_LAST = 'L',
    MARKET_CENTER_OFFICIAL_CLOSE = 'M',
    /**
     * Next Day Trade (Next Day Clearing)
     */
    NEXT_DAY_TRADE = 'N',
    MARKET_CENTER_OPENING_TRADE = 'O',
    PRIOR_REFERENCE_PRICE = 'P',
    MARKET_CENTER_OFFICIAL_OPEN = 'Q',
    SELLER = 'R',
    EXTENDED_HOURS_TRADE = 'T',
    EXTENDED_HOURS_SOLD = 'U',
    STOCK_OPTION_TRADE = 'V',
    CROSS_TRADE = 'X',
    /**
     * Sold (Out of sequence)
     */
    SOLD_OUT_OF_SEQUENCE = 'Z',
    DERIVATIVELY_PRICED = '4',
    MARKET_CENTER_REOPENING_TRADE = '5',
    MARKET_CENTER_CLOSING_TRADE = '6',
    CORRECTED_CONSOLIDATED_PRICE = '9'
  }

  /**
   * Trade Indicator (NASDAQ)
   */
  export enum NASDAQ {
    REGULAR_SALE = '@',
    ACQUISITION = 'A',
    /**
     * on NYSE: Average Price Trade
     */
    BUNCHED_TRADE = 'B',
    /**
     * Cash Sale
     */
    CASH_TRADE = 'C',
    DISTRIBUTION = 'D',
    INTERMARKET_SWEEP_ORDER = 'F',
    BUNCHED_SOLD_TRADE = 'G',
    PRICE_VARIATION_TRADE = 'H',
    ODD_LOT_TRADE = 'I',
    /**
     * On NYSE: Rule 127 or Rule 155
     */
    RULE_155 = 'K',
    SOLD_LAST = 'L',
    MARKET_CENTER_OFFICIAL_CLOSE = 'M',
    NEXT_DAY_TRADE = 'N',
    /**
     * Opening Prints
     */
    MARKET_CENTER_OPENING_TRADE = 'O',
    PRIOR_REFERENCE_PRICE = 'P',
    MARKET_CENTER_OFFICIAL_OPEN = 'Q',
    SELLER = 'R',
    SPLIT_TRADE = 'S',
    /**
     * On NYSE: Extended Hours Trade
     */
    FORM_T = 'T',
    /**
     * Sold Out of Sequence
     */
    EXTENDED_HOURS_SOLD = 'U',
    STOCK_OPTION_TRADE = 'V',
    AVERAGE_PRICE_TRADE = 'W',
    CROSS_TRADE = 'X',
    /**
     * Yellow Flag Regular Trade
     */
    YELLOW_FLAG = 'Y',
    SOLD_OUT_OF_SEQUENCE = 'Z',
    STOPPED_STOCK_REGULAR_TRADE = '1',
    STOPPED_STOCK_SOLD_LAST = '2',
    STOPPED_STOCK_SOLD_OUT_OF_SEQUENCE = '3',
    DERIVATIVELY_PRICED = '4',
    /**
     * Re-Opening Prints
     */
    MARKET_CENTER_REOPENING_TRADE = '5',
    /**
     * Closing Prints
     */
    MARKET_CENTER_CLOSING_TRADE = '6',
    /**
     * Corrected Consolidated Close
     */
    CORRECTED_CONSOLIDATED_PRICE = '9'
  }


  /**
   * Trade Indicator (Montreal)
   */
  export enum Montreal {
    /**
     * EFP Reporting (price field zero filled)
     *
     * On NYSE: Automatic Execution
     */
    EFP_REPORTING = 'E',
    /**
     * Strategy Reporting (price field zero filled)
     */
    STRATEGY_REPORTING = 'P',
    /**
     * ON NYSE/NASDAQ: Market Center Official Open
     */
    MOC_TRADE = 'Q',
    /**
     * Reference price (volume field zero filled)
     */
    REFERENCE_PRICE = 'S',
    /**
     * ON NYSE/NASDAQ: Stock Option Trade
     */
    SUBORDINATE_VOTING_SHARES = 'V',
    ACTUAL_TRANSACTION = ' '
  }

  /**
   * Trade Indicator (Canadian Equity)
   */
  export enum CanadianEquity {
    REGULAR_SALE = '@',
    /**
     * On NYSE: Average Price Trade
     * On NASDAQ: Bunched Trade
     */
    CONDITIONAL = 'B',
    /**
     * On NYSE/NASDAQ: Cash Trade
     */
    CONTINGENT_CROSS = 'C',
    /**
     * On NYSE/NASDAQ: Distribution
     */
    DERIVATIVE = 'D',
    /**
     * On NYSE: Automatic Execution
     * On Montreal: EFP Reporting
     */
    NON_BOARD_LOT = 'E',
    /**
     * On NYSE/NASDAQ: Bunched Sold Trade
     */
    VWAP_CROSS = 'G',
    /**
     * On NYSE/NASDAQ: Odd Lot Trade
     */
    INTENTIONAL = 'I',
    /**
     * On NYSE/NASDAQ: Rule 127 or Rule 155
     */
    DARK = 'K',
    /**
     * On NYSE/NASDAQ: Sold Last
     */
    M_ELO = 'L',
    /**
     * ON NYSE/NASDAQ: Market Center Official Close
     */
    MIDPOINT = 'M',
    /**
     * on NYSE: Market Center Opening Trade
     * On NASDAQ: Opening Prints
     */
    BASIS_CROSS = 'O',
    /**
     * on NYSE/NASDAQ: MARKET_CENTER_OFFICIAL_OPEN
     */
    MOC_TRADE = 'Q',
    /**
     * On NASDAQ: Split Trade
     * On Montreal: Reference Price
     */
    SPECIAL_TRADING_SESSION_CROSS = 'S',
    EXTENDED_HOURS_TRADE = 'T',
    /**
     * On NASDAQ: Average Price Trade
     */
    BYPASS_TRADE = 'W',
    /**
     * On NYSE/NASDAQ: Cross Trade
     */
    INTERNAL_CROSS = 'X'
  }

  /**
   * Trade Indicator (CME/CBOT)
   */
  export enum CME {
    REGULAR_SALE = '@',
    /**
     * On NYSE/NASDAQ: Market Center Official Open
     * On Montreal/Canadian Equity: MOC Trade
     */
    OTC_LATE_CORRECTION = 'Q'
  }

  /**
   * Trade Indicator (LSE)
   */
  export enum LSE {
    /**
     * ON NASDAQ: Acquisition
     */
    AUTOMATIC_TRADE = 'A',
    /**
     * On NYSE/NASDAQ: Cash Trade
     * On Canadian Equity: Contingent Cross
     */
    SI_LATE_CORRECTION = 'C',
    /**
     * On NYSE/NASDAQ: Distribution
     * On Canadian Equity: Derivative
     */
    ORDINARY_TRADE_WITH_DELAYED_PUBLICATION = 'D',
    /**
     * On NYSE/NASDAQ: Intermarket Sweep Order
     */
    INTER_FUND_TRANSFER = 'F',
    /**
     * On NYSE/NASDAQ: Bunched Sold Trade
     * On Canadian Equity: VWAP Cross
     */
    NEGOTIATED_TRADE = 'G',
    /**
     * OTC Trade with Immediate Publication
     * ---
     * On Canadian Equity: Intentional
     */
    ODD_LOT_TRADE = 'I',
    /**
     * On NYSE/NASDAQ: Rule 127 or Rule 155
     * On Canadian Equity: Dark
     */
    SI_TRADE = 'K',
    /**
     * On NYSE/NASDAQ: Sold Last
     * On Canadian Equity: M-ELO
     */
    LATE_CORRECTION = 'L',
    /**
     * On NYSE/NASDAQ: Market Center Official Close
     * On Canadian Equity: Midpoint
     */
    NOT_TO_MARK = 'M',
    /**
     * On NYSE/NASDAQ: Next Day Trade
     */
    NEGOTIATED_TRADE2 = 'N',
    /**
     * On NYSE/NASDAQ: Market Center Opening Trade
     * On Canadian Equity: Basis Cross
     */
    ORDINARY_TRADE = 'O',
    /**
     * On NYSE/NASDAQ: Prior Reference Privce
     * On Montreal: Strategy Reporting
     */
    PREVIOUS_DAY_CONTRA = 'P',
    /**
     * On NASDAQ: Split Trade
     * On Montreal: Reference Price
     * On Canadian Equity: Special Trading Session Cross
     */
    SI_TRADE2 = 'S',
    /**
     * On NYSE/Canadian Equity: Extended Hours Trade
     * On NASDAQ: Form T
     */
    OTC_TRADE = 'T',
    /**
     * On NYSE/NASDAQ: Extended Hours Sold
     */
    UNCROSSING_TRADE = 'U'
  }
}
