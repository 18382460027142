/**
 * Montreal Option Marker
 * ---
 * Indicates the trading currency.
 * Valid for Montreal ONLY, otherwise space.
 *
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */
export enum QMMontrealOptionMarker {
  BRITISH_POUND = 'B',
  CANADIAN_DOLLARS = 'C',
  GERMAN_MARK = 'D',
  SWISS_FRANC = 'E',
  EURO = 'F',
  US_DOLLARS = 'U',
  JAPANESE_YEN = 'Y',
  NON_APPLICABLE = ' '
}
