import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  qmFetchVenues,
  qmFetchSymbols,
} from "@berkindale/berkindale-provider-quotemedia-javascript-api";

const initialState = {
  symbols: [],
  isSymbolsLoading: false,
  errorSymbols: null,
  venues: [],
  isVenuesLoading: false,
  errorVenues: null,
};

// FUNCTIONS TO FETCH MARKET DATA

export const fetchSymbols = createAsyncThunk(
  "domain/fetchSymbols",
  async (args, thunkAPI, other) => {
    try {
      const resp = await qmFetchSymbols(args);
      console.log("domainSlice->fetchSymbols->raw response data", resp);
      return resp;
    } catch (error) {
      console.log("fetchSymbols ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchVenues = createAsyncThunk(
  "domain/fetchVenues",
  async (args, thunkAPI, other) => {
    try {
      const resp = await qmFetchVenues(args);
      console.log("domainSlice->fetchVenues->raw response data", resp);
      return resp;
    } catch (error) {
      console.log("fetchVenues ThunkAPI error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// SLICE
export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSymbols.pending, (state) => {
        state.isSymbolsLoading = true;
      })
      .addCase(fetchSymbols.fulfilled, (state, action) => {
        console.log("fetchVenues.fulfilled", action);
        state.symbols = action.payload;
        state.isSymbolsLoading = false;
      })
      .addCase(fetchSymbols.rejected, (state, action) => {
        console.log(
          "ERROR fetchSymbols.rejected",
          action.payload.response.status
        );
        state.isSymbolsLoading = false;
        state.errorSymbols = action.error.message;
      })

      .addCase(fetchVenues.pending, (state) => {
        state.isVenuesLoading = true;
      })
      .addCase(fetchVenues.fulfilled, (state, action) => {
        console.log("fetchVenues.fulfilled", action);
        state.venues = action.payload;
        state.isVenuesLoading = false;
      })
      .addCase(fetchVenues.rejected, (state, action) => {
        console.log(
          "ERROR fetchVenues.rejected",
          action.payload.response.status
        );
        state.isVenuesLoading = false;
        state.errorVenues = action.error.message;
      });
  },
});

// ACTION CREATORS
// export const {} = domain.actions;

// SELECTORS
export const selectSymbols = (state) => state.domain.symbols;
export const selectIsSymbolsLoading = (state) => state.domain.isSymbolsLoading;
export const selectVenues = (state) => state.domain.venues;
export const selectIsVenuesLoading = (state) => state.domain.isVenuesLoading;

// SLICE REDUCER
export const domainReducer = domainSlice.reducer;
