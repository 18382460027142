import { qmFetchSentiment } from "@berkindale/berkindale-provider-quotemedia-javascript-api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { RootState } from "../../app/store";
import { getPreviousDay } from "../common/utils";

const maxDate = getPreviousDay(new Date(), true, false);

export interface SentimentFilter {
  symbol: string;
  start: number;
  end: number;
}

// Shared data types between news and tweets
interface NewsOrTweetRecord {
  id: number;
  timestamp: number;
  content: string;
  sentiment: number;
  url: string;
}

export interface SentimentState {
  news: {
    [key: number]: {
      records: NewsOrTweetRecord[];
      averageSentiment: number | null;
    };
  };
  tweets: {
    [key: number]: {
      records: NewsOrTweetRecord[];
      averageSentiment: number | null;
    };
  };
  newsLoading: boolean;
  tweetsLoading: boolean;
  newsError: string | null;
  tweetsError: string | null;
  filter: SentimentFilter;
}

const initialState: SentimentState = {
  news: {},
  tweets: {},
  newsLoading: false,
  tweetsLoading: false,
  newsError: null,
  tweetsError: null,
  filter: {
    symbol: "TD",
    start: maxDate.getTime(),
    end: maxDate.getTime(),
  },
};

export interface FetchSentimentInput {
  isNews: boolean;
  symbol: string;
  jwtToken: string;
  start: number;
  end: number;
  hash: number;
}

export interface FetchSentimentResponse {
  hash: number;
  dataObj: {
    records: NewsOrTweetRecord[];
    averageSentiment: number;
  };
}

export const fetchSentiment = createAsyncThunk<
  // Response type
  FetchSentimentResponse,
  // Input type
  FetchSentimentInput
>("fetchSentiment", async (input, thunkAPI) => {
  try {
    const { isNews, symbol, jwtToken, hash } = input;
    const start = format(input.start, "yyyy-MM-dd");
    const end = format(input.end, "yyyy-MM-dd");
    const resp = await qmFetchSentiment({
      type: isNews ? "news" : "twitter",
      symbol,
      start,
      end,
      jwtToken,
    });
    const records: NewsOrTweetRecord[] = resp.records;
    const averageSentiment: number = resp.averageSentiment;
    return {
      hash,
      dataObj: {
        records,
        averageSentiment,
      },
    };
  } catch (error) {
    console.log("fetchSentiment ThunkAPI error", error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const sentimentSlice = createSlice({
  name: "sentiment",
  initialState,
  reducers: {
    setFetchNewsLoading: (state, action) => {
      state.newsLoading = action.payload.status;
    },
    setFetchTweetsLoading: (state, action) => {
      state.tweetsLoading = action.payload.status;
    },
    updateSentimentFilter: (state, action) => {
      state.filter.symbol = action.payload.symbol;
      state.filter.start = action.payload.start;
      state.filter.end = action.payload.end;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSentiment.pending, (state, action) => {
        const { isNews } = action.meta.arg;
        if (isNews) {
          state.newsLoading = true;
          state.newsError = null;
        } else {
          state.tweetsLoading = true;
          state.tweetsError = null;
        }
      })
      .addCase(fetchSentiment.fulfilled, (state, action) => {
        const { isNews } = action.meta.arg;
        const { hash, dataObj } = action.payload;
        if (isNews) {
          state.news[hash] = dataObj;
          state.newsLoading = false;
          state.newsError = null;
        } else {
          state.tweets[hash] = dataObj;
          state.tweetsLoading = false;
          state.tweetsError = null;
        }
      })
      .addCase(fetchSentiment.rejected, (state, action) => {
        const { isNews } = action.meta.arg;
        console.log("fetchSentiment.rejected -> action.error", action.error);
        if (isNews) {
          state.newsLoading = false;
          state.newsError = action.error.message!;
        } else {
          state.tweetsLoading = false;
          state.tweetsError = action.error.message!;
        }
      });
  },
});

export const selectNews = (state: RootState) => state.sentiment.news;
export const selectTweets = (state: RootState) => state.sentiment.tweets;
export const selectNewsLoading = (state: RootState) =>
  state.sentiment.newsLoading;
export const selectTweetsLoading = (state: RootState) =>
  state.sentiment.tweetsLoading;
export const selectNewsError = (state: RootState) => state.sentiment.newsError;
export const selectTweetsError = (state: RootState) =>
  state.sentiment.tweetsError;
export const selectSentimentFilter = (state: RootState) =>
  state.sentiment.filter;

// ACTION CREATORS
export const {
  setFetchNewsLoading,
  setFetchTweetsLoading,
  updateSentimentFilter,
} = sentimentSlice.actions;

export const sentimentReducer = sentimentSlice.reducer;
