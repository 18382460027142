import { useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Icon from "@mui/material/Icon";
import MenuIcon from "@mui/icons-material/Menu";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { ColorModeContext } from "../../app/App";
import { useAppSelector } from "../../app/hooks";
import { selectUserPicture } from "../login/loginSlice";

const countries = [
  {
    name: "Canada",
    disabled: false,
    hidden: false,
  },
  {
    name: "United States",
    disabled: true,
    hidden: false,
  },
  {
    name: "Europe",
    disabled: true,
    hidden: true,
  },
  {
    name: "Japan",
    disabled: true,
    hidden: true,
  },
  {
    name: "Australia",
    disabled: true,
    hidden: true,
  },
];

const helpMenuItems = [
  {
    text: "Dataset Guide",
    path: "#",
    icon: "dataset",
    hidden: true,
  },
  {
    text: "User Guide",
    path: "#",
    icon: "smartphone",
    hidden: true,
  },
  {
    text: "API User Guide",
    path: "api-user-guide",
    icon: "code",
    hidden: false,
  },
  {
    text: "Query API User Guide",
    path: "query-api-user-guide",
    icon: "code",
    hidden: false,
  },
  {
    text: "API Authentication Guide",
    path: "api-authentication-guide",
    icon: "code",
    hidden: false,
  },
  // {
  //   text: "Python SDK Guide",
  //   path: "python-sdk-guide",
  //   icon: "code",
  // },
  {
    text: "Analytics Guide",
    path: "analytics-guide",
    icon: "dataset",
    hidden: false,
  },
  {
    text: "Frequently Asked Questions",
    path: "#",
    icon: "quiz",
    hidden: true,
  },
  {
    text: "Contact Support",
    path: "contact-support",
    icon: "contact_support",
    hidden: false,
  },
];

const notifications = [
  { text: "Analytics ready", icon: "analytics" },
  { text: "Volatility alert for TD", icon: "warning" },
  { text: "Marie Berkindale shared a workspace", icon: "person" },
  { text: "Report ready", icon: "summarize" },
];

const userMenuItems = [
  { name: "Settings", icon: "settings", hidden: true },
  { name: "Profile", icon: "person", path: "profile", hidden: false },
  { name: "Subscriptions", icon: "subscriptions", hidden: true },
  { name: "Payment & Billing", icon: "credit_card", hidden: true },
  { name: "Security", icon: "lock", path: "security", hidden: false },
  { name: "Logout", icon: "logout", hidden: false },
];

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerwidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerwidth,
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface NavBarProps {
  open?: boolean;
  handleDrawerOpen: () => void;
  drawerWidth: number;
}

export function NavBar({ open, handleDrawerOpen, drawerWidth }: NavBarProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElHelp, setAnchorElHelp] = useState<null | HTMLElement>(null);
  const [anchorElNotifications, setAnchorElNotifications] =
    useState<null | HTMLElement>(null);
  const [country, setCountry] = useState("Canada");
  const {
    user: { attributes: profile },
    signOut,
  } = useAuthenticator((context) => [context.user]);

  const userPicture = useAppSelector(selectUserPicture);
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenHelpMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleCloseHelpMenu = () => {
    setAnchorElHelp(null);
  };

  const handleOpenNotificationsTray = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotificationsTray = () => {
    setAnchorElNotifications(null);
  };

  const userInitials =
    profile &&
    profile &&
    typeof profile.given_name === "string" &&
    typeof profile.family_name === "string"
      ? `${Array.from(profile.given_name)[0]}${
          Array.from(profile.family_name)[0]
        }`
      : null;

  const countriesArray = countries
    .filter((country) => {
      if (process.env.REACT_APP_HIDE_UNFINISHED) {
        return !country.hidden;
      } else {
        return country;
      }
    })
    .map((country) => (
      <MenuItem
        key={country.name}
        value={country.name}
        disabled={country.disabled ? true : false}
      >
        {country.name}
      </MenuItem>
    ));

  const helpMenuItemsArray = helpMenuItems
    .filter((helpItem) => {
      if (process.env.REACT_APP_HIDE_UNFINISHED) {
        return !helpItem.hidden;
      } else {
        return helpItem;
      }
    })
    .map((helpItem) => (
      <MenuItem
        key={helpItem.text}
        value={helpItem.text}
        onClick={() => {
          navigate(`/${helpItem.path}`);
          handleCloseHelpMenu();
        }}
        divider
        disabled={helpItem.hidden ? true : false}
      >
        <ListItemIcon>
          <Icon>{helpItem.icon}</Icon>
        </ListItemIcon>
        <ListItemText>{helpItem.text}</ListItemText>
      </MenuItem>
    ));

  const userMenuItemsArray = userMenuItems
    .filter((item) => {
      if (process.env.REACT_APP_HIDE_UNFINISHED) {
        return !item.hidden;
      } else {
        return item;
      }
    })
    .map((item) => (
      <MenuItem
        key={item.name}
        onClick={
          item.name !== "Logout"
            ? () => {
                if (item.path) navigate(`/${item.path}`);
                handleCloseUserMenu();
              }
            : signOut
        }
        divider
      >
        <ListItemIcon>
          <Icon>{item.icon}</Icon>
        </ListItemIcon>
        <ListItemText>{item.name}</ListItemText>
      </MenuItem>
    ));

  const notificationsArray = notifications.map((notification) => (
    <MenuItem
      key={notification.text}
      onClick={handleCloseNotificationsTray}
      divider
    >
      <ListItemIcon>
        <Icon>{notification.icon}</Icon>
      </ListItemIcon>
      <ListItemText>{notification.text}</ListItemText>
    </MenuItem>
  ));

  return (
    <AppBar
      position="fixed"
      open={open}
      drawerwidth={drawerWidth}
      color="primary"
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Berkindale Analytics
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexGrow: 0, alignItems: "center" }}>
          <Tooltip title="Select country" placement="left">
            <Select
              variant="standard"
              // autoWidth
              value={country}
              label="Country"
              onChange={handleCountryChange}
              sx={{ minWidth: "122px", mr: 2 }}
            >
              {countriesArray}
            </Select>
          </Tooltip>
          <Tooltip title="Toggle light/dark mode">
            <IconButton
              sx={{ mx: 1 }}
              onClick={colorMode.toggleColorMode}
              color="inherit"
            >
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Toggle help tray">
            <IconButton onClick={handleOpenHelpMenu} sx={{ mx: 1 }}>
              <HelpOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElHelp}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElHelp)}
            onClose={handleCloseHelpMenu}
          >
            <MenuItem disabled divider sx={{ fontSize: "20px" }}>
              Help
            </MenuItem>
            {helpMenuItemsArray}
          </Menu>
          {!process.env.REACT_APP_HIDE_UNFINISHED && (
            <>
              <Tooltip title="Toggle notifications panel">
                <IconButton
                  onClick={handleOpenNotificationsTray}
                  sx={{ mx: 1 }}
                >
                  <NotificationsActiveOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElNotifications}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNotifications)}
                onClose={handleCloseNotificationsTray}
              >
                <MenuItem disabled divider sx={{ fontSize: "20px" }}>
                  Notifications - 4 new
                </MenuItem>
                {notificationsArray}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Button variant="outlined" sx={{ mt: "6px" }}>
                    Clear All Notifications
                  </Button>
                </Box>
              </Menu>
            </>
          )}

          <IconButton onClick={handleOpenUserMenu} sx={{ pr: 0 }}>
            {!userPicture ? (
              <Avatar>{userInitials}</Avatar>
            ) : (
              <Avatar alt={profile?.given_name} src={userPicture} />
            )}
            <Icon>arrow_drop_down</Icon>
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {userMenuItemsArray}
            {/* <MenuItem key="signOut" onClick={signOut}>
              <ListItemIcon>
                <Icon>logout</Icon>
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem> */}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
