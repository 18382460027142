export interface LinksInterface {
  id: number;
  name: string;
  icon: null;
  markdownFile: string;
  routerPath: string;
}

export const pythonSDKLinks = [
  {
    id: 1,
    name: "Python SDK Guide",
    icon: null,
    markdownFile: "/help/python-sdk-guide.md",
    routerPath: "/python-sdk-guide",
  },
];

export const apiAuthenticationGuideLinks = [
  {
    id: 1,
    name: "API Authentication Guide",
    icon: null,
    markdownFile: "/help/api-authentication-guide.md",
    routerPath: "/api-authentication-guide",
  },
];
export const analyticsLinks = [
  {
    id: 1,
    name: "Advanced insights overview",
    icon: null,
    markdownFile: "/analytics-docs/advanced-insights/index.md",
    routerPath: "/analytics-guide/advanced-insights",
  },
  {
    id: 2,
    name: "Daily Summary",
    icon: null,
    markdownFile: "/analytics-docs/advanced-insights/daily_summary.md",
    routerPath: "/analytics-guide/advanced-insights/daily_summary",
  },
  {
    id: 3,
    name: "Trades",
    icon: null,
    markdownFile: "/analytics-docs/advanced-insights/trades.md",
    routerPath: "/analytics-guide/advanced-insights/trades",
  },
  {
    id: 4,
    name: "Top of book",
    icon: null,
    markdownFile: "/analytics-docs/advanced-insights/top_of_book.md",
    routerPath: "/analytics-guide/advanced-insights/top_of_book",
  },
  {
    id: 5,
    name: "Orders",
    icon: null,
    markdownFile: "/analytics-docs/advanced-insights/orders.md",
    routerPath: "/analytics-guide/advanced-insights/orders",
  },
  {
    id: 6,
    name: "Depth",
    icon: null,
    markdownFile: "/analytics-docs/advanced-insights/depth.md",
    routerPath: "/analytics-guide/advanced-insights/depth",
  },
];
