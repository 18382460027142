import {
  qmFetchAnalytics,
  qmFetchDownloads,
  qmFetchDownloadSearch,
  QMEventsInput,
  qmFetchEvents,
  qmFetchOrderBook,
  qmFetchSentiment,
  qmFetchTradesQuotes,
  qmFetchVenues,
  qmFetchSymbols,
} from "./quotemedia/quotemediaApis";

export {
  qmFetchAnalytics,
  qmFetchDownloads,
  qmFetchDownloadSearch,
  QMEventsInput,
  QMDownloadSearchInput,
  qmFetchEvents,
  qmFetchOrderBook,
  qmFetchSentiment,
  qmFetchTradesQuotes,
  qmFetchVenues,
  qmFetchSymbols,
} from "./quotemedia/quotemediaApis";

export * as domain from "@berkindale/berkindale-provider-quotemedia-domain";

// (async () => {
//   try {
//     let events;
// const dataset = "quotes-1minute";
// const dataset = "trades-1minute";
// const dataset = "quotes";
// const dataset = "trades";
// const symbol = "AC:CA"; // use for qmFetchEvents, qmFetchOrderBook
// const symbol = "AC"; // use for qmFetchEvents, qmFetchOrderBook
// const symbol = "AC:CC"; // use for trades, quotes, analytics
// const venue = "TSX";
// const venueSuffix = "CA.TSX";
//   const exchangeId = "TSX";
// const date = "2022-07-15";
// const date = "2022-07-11";
// const date = "2023-01-04";
// const name = "trades_trading_cost";
// const name = "trades_ohlcv";
// const name = "trades_trading_imbalance";
// const name = "trades_fragmentation";
// const name = "tob_market_quote_insights";
// const name = "tob_quote_activity_consolidated";
// const name = "tob_quote_activity_venue";

// const from = 1657547759000;
// const from = 1657546200000; // 2022-07-11 9:30:00 ET
// const from = 1657560600000; // 2022-07-11 1:30:00 ET
// const from = 1672842720000 // 2023-01-4 9:32:00 ET
// const startTimestamp = new Date(date + " 13:28:00 GMT").getTime();
// const endTimestamp = new Date(date + " 20:02:00 GMT").getTime();
// const ACUntilBkSequence = 3103723;
// const csvPath = "data";
// const jwtToken = "";
// events = await qmFetchAnalytics({
//   name,
//   symbol,
//   venue,
//   date,
//   startTimestamp,
//   endTimestamp,
//   jwtToken,
// });
// await qmFetchDownloads({
//   key: "quotemedia/qm_marketfeed_ca/feed=ti.clstsx/year=2022/day=0711/bucket=Q-U/3b182a0b11cc482abdc2726e66f7683c.gz.parquet",
//   jwtToken,
// });
// events = await qmFetchDownloadSearch({
//   dataset,
//   feed: "ti.cbbotsx",
//   date,
//   jwtToken,
// });
// events = await qmFetchEvents({ symbol, date, venueSuffix, from, jwtToken });
// events = await qmFetchOrderBook({
//   symbol,
//   venueSuffix,
//   date,
//   untilBkSequence: ACUntilBkSequence,
//   jwtToken,
// });
// events = await qmFetchTradesQuotes({ dataset, symbol, date, jwtToken });
// events = await qmFetchVenues({ jwtToken });
// events = await qmFetchSymbols({ jwtToken, csvPath });
// events.sort((a, b) => a.timestamp - b.timestamp);
// console.log("response.data", events); // use this instead of the for loop below if "events" isn't in an array
// for (const r of events.slice(0, 5)) {
//   console.info(
//     "** Row: %s",
//     JSON.stringify(r).replace(/"([^"]+)":/g, " $1:")
//   );
// }
//   } catch (e) {
//     console.error("main() Error: ", e);
//   }
// })();
