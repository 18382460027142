/**
 * Halt Indicator Flag
 * ---
 * In addition to the values in the table below, the code '-' is used then no change in Security Status is being reported. 
 * i.e if there is no active halt (Halt Status = 03) or the message update pertains to a change in Reg SHO status (Halt Status = 08).
 * 
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */
export enum QMHaltFlagTODO {
  // Different options per exchange !!
  TradingResumption = "Trading Resumption"
}
