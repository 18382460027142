import {
  QMLevel2Event,
  QMTrade1Minute,
  QMQuote1Minute,
  QMBookQueueChanges,
  QMVenueShort,
  QMAnalytics,
} from "@berkindale/berkindale-provider-quotemedia-domain";
import axios from "axios";
import { _Object } from "@aws-sdk/client-s3";

const QuoteMediaApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
});

export interface QMAnalyticsInput {
  name: string;
  symbol: string;
  date: string;
  venue: string;
  startTimestamp: number;
  endTimestamp: number;
  extraArgs?: object | null;
  jwtToken: string;
}

export async function qmFetchAnalytics(
  input: QMAnalyticsInput
): Promise<QMAnalytics[]> {
  try {
    const {
      name,
      symbol,
      date,
      venue,
      startTimestamp,
      endTimestamp,
      extraArgs,
      jwtToken,
    } = input;
    const qs: string[] = [];
    qs.push(`start_timestamp=${startTimestamp}`);
    qs.push(`end_timestamp=${endTimestamp}`);
    if (extraArgs !== null) {
      for (const arg in extraArgs) {
        qs.push(`${arg}=${extraArgs[arg]}`);
      }
    }
    const qsStr = qs.length > 0 ? `?${qs.join("&")}` : "";
    const response = await QuoteMediaApiClient.get<any>(
      `/analytics/${name}/symbols/${symbol}/dates/${date}/venues/${venue}${qsStr}`,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface QMDownloadInput {
  key: string;
  jwtToken: string;
}

interface QMDownloadResponse {
  url: string;
}

/**
 * Example:
 * {API_BASE_URL}/downloads/presign-url?key=quotemedia/${ATHENA_DEFAULT_DATABASE}/feed%3Dti.clstsx/year%3D2022/day%3D0711/bucket%3DQ-U/3b182a0b11cc482abdc2726e66f7683c.gz.parquet
 */
export async function qmFetchDownloads(input: QMDownloadInput): Promise<void> {
  const { key, jwtToken } = input;
  const response = await QuoteMediaApiClient.get<QMDownloadResponse>(
    `/downloads/presign-url?key=${key}`,
    {
      headers: {
        Authorization: jwtToken,
      },
    }
  );
  const url = response.data.url;
  axios({
    url,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data.url]));
    const link = document.createElement("a");
    link.href = url;
    // link.setAttribute('download', 'myFile.csv'); //or any other extension
    link.setAttribute("download", key);
    document.body.appendChild(link);
    link.click();
  });
}

export interface QMDownloadSearchInput {
  dataset: string;
  feed: string;
  date: string;
  jwtToken: string;
}

export async function qmFetchDownloadSearch(
  input: QMDownloadSearchInput
): Promise<any> {
  try {
    const { dataset, feed, date, jwtToken } = input;
    const response = await QuoteMediaApiClient.get<_Object[]>(
      `/downloads/search/datasets/${dataset}/feeds/${feed}/dates/${date}`,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );
    console.log("response.data", response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface QMEventsInput {
  symbol: string;
  venueSuffix: string;
  date: string;
  from?: number; // Filter results which have their timestamp after or equal this UTC Epoch timestamp (in milliseconds)
  limit?: number; // Set a maximum nb of records to return, useful for testing
  jwtToken: string;
  bkSequenceNumber?: number;
}

interface QMEventsResponse {
  results: QMLevel2Event[];
}

/**
 * Example:
 * {API_BASE_URL}/datasets/main-events/symbols/TD:CA/dates/2022-06-15?from=1657906200000&limit=5
 */
export async function qmFetchEvents(
  input: QMEventsInput
): Promise<QMLevel2Event[]> {
  try {
    const { symbol, venueSuffix, date, from, limit, jwtToken } = input;
    console.log("quotemediaApis.ts->symbol", symbol);
    const symbolAndSuffix = symbol + ":" + venueSuffix;
    const qs: string[] = [];
    from && qs.push(`from=${from}`);
    limit && qs.push(`limit=${limit}`);
    const qsStr = qs.length > 0 ? `?${qs.join("&")}` : "";
    console.log("js-api-> qmFetchEvents input", symbolAndSuffix, date, qsStr);
    const response = await QuoteMediaApiClient.get<QMEventsResponse>(
      `/datasets/main-events/symbols/${symbolAndSuffix}/dates/${date}${qsStr}`,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );
    console.log("js-api-> qmFetchEvents response", response);
    // TODO: eventually the JAVA API will be amended to return a top-level array.
    // When that happens we will need to change from `return response.data.results` to `return response.data`.
    // We will also have to change from QMEventsResponse to QMLevel2Event[].
    // if (typeof response.data === "object") {
    //   if (response.data.rows && Array.isArray(response.data.rows)) {
    //     return response.data.rows;
    //   } else if (
    //     response.data.results &&
    //     Array.isArray(response.data.results)
    //   ) {
    //     return response.data.results;
    //   }
    // }
    // return response.data;
    return response.data.results;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

interface BidOrAsk {
  side: string;
  price: number;
  size: string;
  numOrders: number;
}

export interface QMOrderBookInput {
  symbol: string;
  venueSuffix: string;
  date: string;
  untilBkSequence: number;
  jwtToken: string;
}

export async function qmFetchOrderBook(
  input: QMOrderBookInput
): Promise<QMBookQueueChanges[]> {
  try {
    const { symbol, venueSuffix, date, untilBkSequence, jwtToken } = input;
    const symbolAndSuffix = symbol + ":" + venueSuffix;
    const qs: string[] = [];
    untilBkSequence && qs.push(`untilBkSequence=${untilBkSequence}`);
    const qsStr = qs.length > 0 ? `?${qs.join("&")}` : "";
    const queuesAxiosRequest = QuoteMediaApiClient.get<QMBookQueueChanges[]>(
      `/datasets/orderbook-queues/symbols/${symbolAndSuffix}/dates/${date}${qsStr}`,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );

    const queuesResponse = await queuesAxiosRequest;
    return queuesResponse.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface SentimentObject {
  id: number;
  datetime: any;
  label: string;
  text: string;
  url: string;
}

export interface QMSentimentInput {
  type: string;
  symbol: string;
  start: string;
  end: string;
  jwtToken: string;
}

export interface QMSentimentResponse {
  news: SentimentObject[];
  sentiment_score: number;
}

export async function qmFetchSentiment(input: QMSentimentInput): Promise<any> {
  try {
    const { type, symbol, start, end, jwtToken } = input;
    const response = await QuoteMediaApiClient.get<QMSentimentResponse>(
      `/sentiment/${type}/symbols/${symbol}/startdate/${start}/enddate/${end}`,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );
    return response.data; //, response.data.headlines, response.data.storys
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface QMTradesQuotesInput {
  dataset: string;
  symbol: string;
  date: string;
  from?: number; // Filter results which have their timestamp after or equal this UTC Epoch timestamp (in milliseconds)
  limit?: number;
  jwtToken: string;
}

export async function qmFetchTradesQuotes(
  input: QMTradesQuotesInput
): Promise<any> {
  try {
    const { dataset, symbol, date, from, limit, jwtToken } = input;

    const qs: string[] = [];
    from && qs.push(`from=${from}`);
    limit && qs.push(`limit=${limit}`);
    const qsStr = qs.length > 0 ? `?${qs.join("&")}` : "";
    const response = await QuoteMediaApiClient.get<any>(
      `/datasets/${dataset}/symbols/${symbol}/dates/${date}${qsStr}`,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );
    if (typeof response.data === "object") {
      if (response.data.rows && Array.isArray(response.data.rows)) {
        return response.data.rows;
      } else if (
        response.data.results &&
        Array.isArray(response.data.results)
      ) {
        return response.data.results;
      }
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface QMVenuesInput {
  jwtToken: string;
}

export async function qmFetchVenues(
  input: QMVenuesInput
): Promise<QMVenueShort[]> {
  try {
    const { jwtToken } = input;
    const response = await QuoteMediaApiClient.get<any>(`/datasets/venues`, {
      headers: {
        Authorization: jwtToken,
      },
    });
    if (typeof response.data === "object") {
      if (response.data.rows && Array.isArray(response.data.rows)) {
        return response.data.rows;
      } else if (
        response.data.results &&
        Array.isArray(response.data.results)
      ) {
        return response.data.results;
      }
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface QMSymbolsInput {
  csvPath: string;
  jwtToken: string;
}

export async function qmFetchSymbols(input: QMSymbolsInput): Promise<any> {
  try {
    const { jwtToken, csvPath } = input;
    const qs: string[] = [];
    csvPath && qs.push(`csvPath=${csvPath}`);
    const qsStr = qs.length > 0 ? `?${qs.join("&")}` : "";
    const response = await QuoteMediaApiClient.get<any>(
      `/datasets/symbols${qsStr}`,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );
    if (typeof response.data === "object") {
      if (response.data.rows && Array.isArray(response.data.rows)) {
        return response.data.rows;
      } else if (
        response.data.results &&
        Array.isArray(response.data.results)
      ) {
        return response.data.results;
      }
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export interface QMFetchMainFeedEventsAthenaInput {
  symbol: string;
  dt: string;
  /**
   * Filter results which have their timestamp after or equal this UTC Epoch timestamp (in milliseconds)
   */
  since?: number;
  /**
   * Page to return
   */
  page?: number;
  jwtToken: string;
}
interface QMFetchMainFeedEventsResponseAthena {
  rows: QMLevel2Event[];
}

/**
 * Example:
 * {API_BASE_URL}/order-events/symbols/SU:CA/dates/2022-07-11
 */
export async function qmFetchMainFeedEventsAthena(
  input: QMFetchMainFeedEventsAthenaInput
): Promise<QMLevel2Event[]> {
  try {
    const { symbol, dt, since, page, jwtToken } = input;

    // Refactor me
    const qs: string[] = [];
    since && qs.push(`since=${since}`);
    page && qs.push(`page=${page}`);
    const qsStr = qs.length > 0 ? `?${qs.join("&")}` : "";

    const response =
      await QuoteMediaApiClient.get<QMFetchMainFeedEventsResponseAthena>(
        `/order-events/symbols/${symbol}/dates/${dt}${qsStr}`,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
    // console.debug("keys = ", Object.keys(response.data));

    const results = response.data.rows;
    results.sort((a, b) => {
      if (a.bkSequenceNumber > b.bkSequenceNumber) {
        return 1;
      } else if (a.bkSequenceNumber === b.bkSequenceNumber) {
        return 0;
      } else {
        return -1;
      }
    });

    return results;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
