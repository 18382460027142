import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Provider } from "react-redux";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { App } from "./app/App";
import { store } from "./app/store";
import { authConfig, storageConfig } from "./amplify-config";

// This replaces the Amplify.configure(awsExports) call that was required when we used the amplify-cli to run `amplify init` `amplify-add` and `amplify-pull`. Now we use the Amplify Auth libraries on their own without using the amplify-cli.
Amplify.configure({ Auth: authConfig, Storage: storageConfig });

// Disables all logging globally in production or if set via a webapp/.env file
// console.log("Process.env=", process.env);
if (
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_NOLOG === "true"
) {
  // console.log("Setting NOLOG")
  console.log("Turning logging off.");
  console.log = () => null;
  console.debug = () => null;
  console.info = () => null;
  globalThis.console.log = () => null;
  globalThis.console.debug = () => null;
  globalThis.console.info = () => null;
}

ReactDOM.render(
  <Authenticator.Provider>
    <Provider store={store}>
      <App />
    </Provider>
  </Authenticator.Provider>,
  document.getElementById("root")
);
