/**
 * Halt Status
 * ---
 * Suspended trading occurs when the U.S. Securities and Exchange Commission (SEC) intervenes in the market to halt trading activity due to serious concerns about a company’s assets, operations, or other financial information.
 *
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */

export enum QMHaltStatus {
  UNKNOWN_REASON = 0,
  QUOTE_RESUMPTION = 1,
  TRADING_HALT = 2,
  TRADING_RESUMPTION = 3,
  OPENING_DELAY = 4,
  NO_OPEN_NO_RESUME = 5,
  SURVEILLANCE_INTERVENTION_PHASE = 6,
  PRE_OPENING_PHASE = 7,
  NO_TRADING_STATUS_CHANGE = 8
}
