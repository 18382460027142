
/**
 * Instrument Type
 * ---
 * Found in Canadian Markets: Equity, Equity Option, ETF, Future, Future Option, Index, Money Market Fund, Mutual Fund
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */
export class QMInstrumentTypeWithStringValue {
  public static readonly CASH = new QMInstrumentTypeWithStringValue(1, 'cash');
  public static readonly BOND = new QMInstrumentTypeWithStringValue(2, 'bond');
  // public static readonly COMPOSITE = new QMInstrumentTypeWithStringValue(3, '??? composite');
  public static readonly FUTURE = new QMInstrumentTypeWithStringValue(4, 'future');
  public static readonly FUTURE_OPTION = new QMInstrumentTypeWithStringValue(5, 'future option');
  public static readonly FOREX = new QMInstrumentTypeWithStringValue(6, 'forex');
  public static readonly INDEX = new QMInstrumentTypeWithStringValue(7, 'index');
  public static readonly MUTUAL_FUND = new QMInstrumentTypeWithStringValue(8, 'mutual fund');
  /**
   * A money market fund is a kind of mutual fund that invests in highly liquid, near-term instruments.
   * These instruments include cash, cash equivalent securities, and high-credit-rating, debt-based securities with a short-term maturity (such as U.S. Treasuries).
   * Money market funds are intended to offer investors high liquidity with a very low level of risk.
   * Money market funds are also called money market mutual funds.
   * @see https://www.investopedia.com/terms/m/money-marketfund.asp
   */
  public static readonly MONEY_MARKET_FUND = new QMInstrumentTypeWithStringValue(9, 'money market fund');
  public static readonly MARKET_STATISTIC = new QMInstrumentTypeWithStringValue(10, 'market statistic');
  public static readonly EQUITY = new QMInstrumentTypeWithStringValue(11, 'equity');
  public static readonly EQUITY_OPTION = new QMInstrumentTypeWithStringValue(12, 'equity option');
  public static readonly GOVERNMENT_BOND = new QMInstrumentTypeWithStringValue(13, 'government bond');
  // public static readonly MUNICIPAL_BOND = new QMInstrumentTypeWithStringValue(14, '??? municipal bond');
  public static readonly CORPORATE_BOND = new QMInstrumentTypeWithStringValue(15, 'corporate bond');
  public static readonly ETF = new QMInstrumentTypeWithStringValue(16, 'etf');
  public static readonly FUTURE_SPREAD = new QMInstrumentTypeWithStringValue(17, 'future spread');
  public static readonly UNKNOWN = new QMInstrumentTypeWithStringValue(98, 'unknown');
  public static readonly RATE = new QMInstrumentTypeWithStringValue(99, 'rate');

  // Prevent instanciation of other types, as we use this class like an enum
  private constructor(public readonly code: number, public readonly name: string) {}
}
