
/**
 * Instrument Type
 * ---
 * Found in Canadian Markets: Equity, Equity Option, ETF, Future, Future Option, Index, Money Market Fund, Mutual Fund
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */
export enum QMInstrumentType {
  /**
   * Invalid Instrument Type:
   * 0    => Halt | lHalt
   * NULL => Imbal | BkPrg | lBkPr
   */
  INVALID = 0,
  CASH = 1,
  BOND = 2,
  // COMPOSITE = 3,
  FUTURE = 4,
  FUTURE_OPTION = 5,
  FOREX = 6,
  INDEX = 7,
  MUTUAL_FUND = 8,
  /**
   * A money market fund is a kind of mutual fund that invests in highly liquid, near-term instruments.
   * These instruments include cash, cash equivalent securities, and high-credit-rating, debt-based securities with a short-term maturity (such as U.S. Treasuries).
   * Money market funds are intended to offer investors high liquidity with a very low level of risk.
   * Money market funds are also called money market mutual funds.
   * @see https://www.investopedia.com/terms/m/money-marketfund.asp
   */
  MONEY_MARKET_FUND = 9,
  MARKET_STATISTIC = 10,
  EQUITY = 11,
  EQUITY_OPTION = 12,
  GOVERNMENT_BOND = 13,
  // MUNICIPAL_BOND = 14,
  CORPORATE_BOND = 15,
  ETF = 16,
  FUTURE_SPREAD = 17,
  UNKNOWN = 98,
  RATE = 99,
}
