/**
 * Range Indicator
 * ---
 * Indicates if the trade is closer to the bid or if it’s closer to the ask
 *
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */

export enum QMRangeIndicator {
  /**
   * Represents (trade <= bid || trade < mid-point)
   */
  BUY_SIDE = 'B',
  /**
   * Represents (trade >= ask || trade > mid-point)
   */
  SELL_SIDE = 'S',
  /**
   * Represents (trade = mid-point)
   */
  IN_RANGE = 'R',
  /**
   * When trade is Out-Of-Sequence (because the spread at original time of the trade is unknown)
   * or there is not both a bid and an offer active.
   */
  UNKNOWN = 'U'
}
