/**
 * Option Quote Indicator
 *
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */

export enum QMOptionQuoteIndicator {
  /**
   * Regular Trading
   */
  REGULAR = ' ',
  /**
   * Non-Firm Quote
   */
  NON_FIRM = 'F',
  ROTATION = 'R',
  TRADING_HALTED = 'T',
  /**
   * Eligible for Automation Execution
   */
  AUTOMATIC = 'A',
  /**
   * Inactive (applies only to Category U FCO Quote)
   */
  INACTIVE = 'I',
  /**
   * Bid from Specialist Book
   */
  BID = 'B',
  /**
   * Offer from Specialist Book
   */
  OFFER = 'O',
  /**
   * Both Bid and Offer from Specialist Book
   */
  BID_AND_OFFER = 'C'
}
