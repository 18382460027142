import { QMVenue } from "../domain/QMVenue";

export const CANADIAN_VENUES_BY_CODE: { [code: string]: QMVenue } =
  Object.fromEntries(
    [
      // Individual venues
      {
        code: "ALPHA",
        group: "ALPHA",
        isoMIC: "XATS",
        shortName: "TSX Alpha",
        longName: "TSX Alpha Exchange",
        consolidated: false,
        symbolSuffix: "APH",
        feeds: ["ca.al2", "ca.al1", "ca.al1-quotes"],
        category: "Lit Pools",
      },
      {
        code: "TSX",
        group: "TSX",
        isoMIC: "XTSE",
        shortName: "TSX",
        longName: "Toronto Stock Exchange",
        consolidated: false,
        symbolSuffix: "CA.TSX",
        feeds: ["ca.tl2"],
        category: "Lit Pools",
      },
      {
        code: "CDX",
        group: "TSV",
        isoMIC: "XTSX",
        shortName: "TSX Venture",
        longName: "Canadian Venture Exchange",
        consolidated: false,
        symbolSuffix: "CA.TSXV",
        feeds: ["ca.cl2", "ca.cl1", "ca.cl1-quotes"],
        category: "Lit Pools",
      },

      {
        code: "AQD",
        group: "AQL",
        isoMIC: "NEOD",
        shortName: "NEO-D",
        longName: "NEO-D: NEO Dark (TSX/TSXV Listings)",
        symbolSuffix: "AQD",
        feeds: ["cdfaeq"],
        category: "Dark Pools",
      },
      {
        code: "AQL",
        group: "AQL",
        isoMIC: "NEOE",
        shortName: "NEO-L",
        longName: "NEO-L: NEO Exchange (TSX/TSXV Listings)",
        consolidated: false,
        symbolSuffix: "AQL",
        feeds: ["cdfaeq"],
        category: "Lit Pools",
      },
      {
        code: "AQN",
        group: "AQN",
        isoMIC: "NEON",
        shortName: "NEO-N",
        longName: "NEO-N: NEO ATS (TSX/TSXV Listings)",
        consolidated: false,
        symbolSuffix: "AQN",
        feeds: ["cdfaeq"],
        category: "Lit Pools",
      },
      {
        code: "CHIX",
        group: "CHIX",
        isoMIC: "CHIC",
        shortName: "Nasdaq CXC",
        longName: "Nasdaq CXC (Chi-X) ATS",
        symbolSuffix: "CHI",
        feeds: ["cdfchx"],
        category: "Lit Pools",
      },
      {
        code: "CX2",
        group: "CHIX",
        isoMIC: "XCX2",
        shortName: "Nasdaq CX2",
        longName: "Nasdaq CX2 ATS",
        consolidated: false,
        symbolSuffix: "CX2",
        feeds: ["cdfcx2"],
        category: "Lit Pools",
      },
      {
        code: "CXD",
        group: "CHIX",
        isoMIC: "XCXD",
        shortName: "Nasdaq CXD",
        longName: "Nasdaq CXD",
        consolidated: false,
        symbolSuffix: "CXD",
        feeds: ["cdfcxd"],
        category: "Dark Pools",
      },
      {
        code: "ICX",
        group: "ICX",
        isoMIC: "XICX",
        shortName: "Instinet Canada",
        longName: "Instinet Canada Cross",
        consolidated: false,
        symbolSuffix: "ICX",
        feeds: ["cdficx"],
        category: "Dark Pools",
      },
      {
        code: "LQN",
        group: "LQN",
        isoMIC: "LICA",
        shortName: "Liquidnet Canada",
        longName: "Liquidnet Canada",
        symbolSuffix: "LQN",
        feeds: ["cdflqn"],
        category: "Dark Pools",
      },
      {
        code: "LYNX",
        group: "OMEGA",
        isoMIC: "LYNX",
        shortName: "Lynx ATS",
        longName: "TradelogiQ: Lynx ATS",
        symbolSuffix: "LYX",
        feeds: ["cdflyx"],
        category: "Lit Pools",
      },
      {
        code: "OMEGA",
        group: "OMEGA",
        isoMIC: "OMGA",
        shortName: "Omega ATS",
        longName: "TradelogiQ: Omega ATS",
        consolidated: false,
        symbolSuffix: "OMG",
        feeds: ["cdfomg"],
        category: "Lit Pools",
      },
      {
        code: "CNQ",
        group: "CNQ",
        isoMIC: "XCNQ",
        shortName: "CSE",
        longName: "Canadian Securities Exchange (CSE)",
        consolidated: false,
        symbolSuffix: "CNX",
        feeds: ["cdfptx"],
        category: "Lit Pools",
      },
      {
        code: "CSE2",
        group: "CSE2",
        isoMIC: "CSE2",
        shortName: "CSE2",
        longName: "Canadian Securities Exchange 2 (CSE2)",
        consolidated: false,
        symbolSuffix: "CS2",
        feeds: ["cdfcs2"],
        category: "Lit Pools",
      },
      {
        code: "PURE",
        group: "PURE",
        isoMIC: "PURE",
        shortName: "PURE Trading",
        longName: "Canadian Securities Exchange (PURE)",
        consolidated: false,
        symbolSuffix: "PUR",
        feeds: ["cdfptx"],
        category: "Lit Pools",
      },
      {
        code: "TCM",
        group: "TCM",
        isoMIC: "MATN",
        shortName: "Triact MatchNow",
        longName: "TriAct MatchNow",
        consolidated: false,
        symbolSuffix: "TCM",
        feeds: ["cdftcm"],
        category: "Dark Pools",
      },

      // Consolidated exchanges
      {
        code: "TSXC",
        group: "TSXC",
        shortName: "TSX Consolidated",
        longName: "TSX Canadian Consolidated Quotes",
        consolidated: true,
        symbolSuffix: "CC.TSX",
        feeds: ["ti.clstsx", "ti.cbbotsx"],
        category: "Consolidated",
      },
      {
        code: "TSXVC",
        group: "TSXVC",
        shortName: "TSX Ventures Consolidated",
        longName: "TSXV Canadian Consolidated Quotes",
        consolidated: true,
        symbolSuffix: "CC.TSXV",
        feeds: ["ti.clstsxv", "ti.cbbotsxv"],
        category: "Consolidated",
      },
    ].map((venue) => [venue.code, venue])
  );

// Additional entries from the list below for which we currently have no feeds.
// https://quotemediasupport.freshdesk.com/support/solutions/articles/13000020527-exchange-code-group-list
//
// {code: "TSI", group: "TSX", isoMIC: "XTSE", name: "Toronto Stock Exchange (Indices)"},
// {code: "TSIED", group: "TSX", isoMIC: "XTSE", name: "Toronto Stock Exchange (EOD Indices)"},
// {code: "MOE", group: "BDM", isoMIC: "XMOC", name: "Montreal Options Exchange"},
// {code: "AQNC", group: "AQNC", name: "NEO Canadian Consolidated Quotes"},
// {code: "CNQC", group: "CNQC", name: "CSE Canadian Consolidated Quotes"},
// {code: "CXB", group: "CXB", name: "Nasdaq Basic Canada"},
// {code: "AQNL", group: "AQN", isoMIC: "NEON", name: "NEO-N: NEO ATS (NEO Listings)"},
// {code: "AQLL", group: "AQL", isoMIC: "NEOE", name: "NEO-L: NEO Exchange (NEO Listings)"},
// {code: "AQDL", group: "AQL", isoMIC: "NEOD", name: "NEO-D: NEO Dark (NEO Listings)"},
// {code: "CMF", group: "CMF", name: "Canadian Mutual Funds/Money Market"},
