import { xxHash32 } from "js-xxhash";
import { Buffer } from "buffer";
import { createFilterOptions } from "@mui/material";

// From: https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key
function sortObjectKeys(obj: any) {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (typeof obj != "object") {
    // it is a primitive: number/string (in an array)
    return obj;
  }
  return Object.keys(obj)
    .sort()
    .reduce((acc: any, key: any) => {
      if (Array.isArray(obj[key])) {
        acc[key] = obj[key].map(sortObjectKeys);
      } else if (typeof obj[key] === "object") {
        acc[key] = sortObjectKeys(obj[key]);
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
}
export const xxhash64_ObjectToUniqueStringNoWhiteSpace = function (Obj: any) {
  let SortedObject = sortObjectKeys(Obj);
  let jsonstring = JSON.stringify(SortedObject, function (k, v) {
    return v === undefined ? "undef" : v;
  });

  // Remove all whitespace
  let jsonstringNoWhitespace = jsonstring.replace(/\s+/g, "");

  let JSONBuffer = Buffer.from(jsonstringNoWhitespace, "binary"); // encoding: encoding to use, optional.  Default is 'utf8'
  return xxHash32(JSONBuffer, 0);
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1): string {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export const disableWeekends = (date: Date) =>
  date.getDay() === 0 || date.getDay() === 6;

export const AutocompleteFilterOptions = createFilterOptions({
  matchFrom: "start",
  limit: 500,
});

export function getPreviousDay(
  date = new Date(),
  weekendsDisabled = false,
  isDownloads = false
): Date {
  const previous = new Date(date.getTime());
  if (weekendsDisabled) {
    if (date.getDay() === 0) {
      previous.setDate(date.getDate() - 2);
    } else if (date.getDay() === 1) {
      previous.setDate(date.getDate() - 3);
    } else if (isDownloads && date.getDay() === 2) {
      previous.setDate(date.getDate() - 4);
    } else if (isDownloads) {
      previous.setDate(date.getDate() - 2);
    } else {
      previous.setDate(date.getDate() - 1);
    }
  } else {
    previous.setDate(date.getDate() - 1);
  }
  return previous;
}

export const setTimeToMidnight = (date: Date | null) => {
  if (date !== null) {
    // set to midnight
    return new Date(date.setHours(0, 0, 0, 0));
  } else {
    return null;
  }
};

// console.log(humanFileSize(1551859712))  // 1.4 GiB
// console.log(humanFileSize(5000, true))  // 5.0 kB
// console.log(humanFileSize(5000, false))  // 4.9 KiB
// console.log(humanFileSize(-10000000000000000000000000000))  // -8271.8 YiB
// console.log(humanFileSize(999949, true))  // 999.9 kB
// console.log(humanFileSize(999950, true))  // 1.0 MB
// console.log(humanFileSize(999950, true, 2))  // 999.95 kB
// console.log(humanFileSize(999500, true, 0))  // 1 MB
