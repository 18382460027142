import { QMDataChannel, QMFeedType } from "../domain/QMDataChannel";

/**
 * All data channels for the canadian market keyed on the feed name.
 */
export const CANADIAN_DATA_CHANNELS_BY_FEED: { [feed: string]: QMDataChannel } =
  Object.fromEntries(
    [
      {
        feed: "cdfaeq",
        name: "NEO",
        feedType: QMFeedType.Data,
        exchanges: ["AQD", "AQDL", "AQL", "AQLL", "AQN", "AQNL"],
        description: "NEO Exchange (NEO-L)/NEO ATS (NEO-N) Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived",
        internalCodes: [
          "RAWFEED_CDFAEQ_RT",
          "RAWFEED_CDFAEQ_DL",
          "SFTP_DAILY_FILE_NEO_EOD",
        ],
        includesMoreThanTrades: [false, true, true],
      },
      {
        feed: "ca.al2",
        name: "ALPHA",
        feedType: QMFeedType.Data,
        exchanges: ["ALPHA"],
        description: "TMX Alpha Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived or use CA.AL1-Quotes.",
        internalCodes: ["RAWFEED_CDFALP_RT", "SFTP_DAILY_FILE_ALPHA_EOD"],
        includesMoreThanTrades: [true, true],
      },
      {
        feed: "cdfchx",
        name: "CHIX",
        feedType: QMFeedType.Data,
        exchanges: ["CHIX", "CXB"],
        description: "Nasdaq Canada CXC Lit Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived.",
        internalCodes: [
          "RAWFEED_CDFCHX_RT",
          "RAWFEED_CDFCHX_DL",
          "SFTP_DAILY_FILE_CXC_EOD",
        ],
        includesMoreThanTrades: [true],
      },
      {
        feed: "cdfcs2",
        name: "CSE2",
        feedType: QMFeedType.Data,
        exchanges: ["CSE2"],
        description: "CSE2 Canada, Trades + Order Book",
        startDate: new Date("2023-05-01"),
        notes: "No L1 quotes - need to be derived.",
        internalCodes: [
          "RAWFEED_CDFCS2_RT",
          "RAWFEED_CDFCS2_DL",
          "SFTP_DAILY_FILE_CS2_EOD",
        ],
        includesMoreThanTrades: [true, true],
      },
      {
        feed: "cdfcx2",
        name: "CX2",
        feedType: QMFeedType.Data,
        exchanges: ["CX2"],
        description: "Nasdaq Canada CX2 Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived.",
        internalCodes: [
          "RAWFEED_CDFCX2_RT",
          "RAWFEED_CDFCX2_DL",
          "SFTP_DAILY_FILE_CX2_EOD",
        ],
        includesMoreThanTrades: [true],
      },
      {
        feed: "cdfcxd",
        name: "CXD",
        feedType: QMFeedType.Data,
        exchanges: ["CXD"],
        description: "Nasdaq Canada CXD Dark Pool",
        startDate: new Date("2022-06-13"),
        notes: "Trades only.",
        internalCodes: [
          "RAWFEED_CDFCXD_RT",
          "RAWFEED_CDFCXD_DL",
          "SFTP_DAILY_FILE_CXD_EOD",
        ],
        includesMoreThanTrades: [false],
      },
      {
        feed: "cdficx",
        name: "ICX",
        feedType: QMFeedType.Data,
        exchanges: ["ICX"],
        description: "Instinet Canada Dark Pool",
        startDate: new Date("2022-06-13"),
        notes: "Trades only.",
        internalCodes: [
          "RAWFEED_CDFICX_RT",
          "RAWFEED_CDFICX_DL",
          "SFTP_DAILY_FILE_INSTANET_EOD",
        ],
        includesMoreThanTrades: [false],
      },
      {
        feed: "cdflqn",
        name: "LQN",
        feedType: QMFeedType.Data,
        exchanges: ["LQN"],
        description: "Liquidnet Canada Dark Pool",
        startDate: new Date("2022-06-13"),
        notes: "Trades only.",
        internalCodes: [
          "RAWFEED_CDFLQN_RT",
          "RAWFEED_CDFLQN_DL",
          "SFTP_DAILY_FILE_LIQUIDNET_EOD",
        ],
        includesMoreThanTrades: [false],
      },
      {
        feed: "cdflyx",
        name: "LYNX",
        feedType: QMFeedType.Data,
        exchanges: ["LYNX"],
        description: "Lynx ATS Trades + Order Book.",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived.",
        internalCodes: [
          "RAWFEED_CDFLYX_RT",
          "RAWFEED_CDFLYX_DL",
          "SFTP_DAILY_FILE_LYNX_EOD",
        ],
        includesMoreThanTrades: [true],
      },
      {
        feed: "cdfomg",
        name: "OMEGA",
        feedType: QMFeedType.Data,
        exchanges: ["OMEGA"],
        description: "Omega Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived.",
        internalCodes: [
          "RAWFEED_CDFOMG_RT",
          "RAWFEED_CDFOMG_DL",
          "SFTP_DAILY_FILE_OMEGA_EOD",
        ],
        includesMoreThanTrades: [true],
      },
      {
        feed: "cdfptx",
        name: "CSE",
        feedType: QMFeedType.Data,
        exchanges: ["CNQ", "PURE"],
        description: "CSE Canada & PURE, Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes:
          "CSE now disseminated on same channel as PURE; effective July 2013. No L1 quotes - need to be derived.",
        internalCodes: [
          "RAWFEED_CDFPTX_RT",
          "RAWFEED_CDFPTX_DL",
          "SFTP_DAILY_FILE_CSE_EOD",
        ],
        includesMoreThanTrades: [true, true],
      },
      {
        feed: "cdftcm",
        name: "TCM",
        feedType: QMFeedType.Data,
        exchanges: ["TCM"],
        description: "TriAct MatchNow Dark Pool",
        startDate: new Date("2022-06-13"),
        notes: "Trades only.",
        internalCodes: [
          "RAWFEED_CDFTCM_RT",
          "RAWFEED_CDFTCM_DL",
          "SFTP_DAILY_FILE_TRIACT_EOD",
        ],
        includesMoreThanTrades: [false],
      },
      {
        feed: "ca.tl2",
        name: "TSX",
        feedType: QMFeedType.Data,
        exchanges: ["TSX"],
        description: "TSX Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived or use CA.TL1-Quotes",
        internalCodes: [
          "RAWFEED_CDFTSXL2_RT",
          "RAWFEED_CDFTSX_DL",
          "SFTP_DAILY_FILE_TSX_EOD",
        ],
        includesMoreThanTrades: [true],
      },
      {
        feed: "ca.cl2",
        name: "TSXV",
        feedType: QMFeedType.Data,
        exchanges: ["TSXV"],
        description: "TSXV Trades + Order Book",
        startDate: new Date("2022-06-13"),
        notes: "No L1 quotes - need to be derived or use CA.CL1-Quotes",
        internalCodes: [
          "RAWFEED_CDFTSXVL2_RT",
          "RAWFEED_CDFTSXV_DL",
          "SFTP_DAILY_FILE_TSXV_EOD",
        ],
        includesMoreThanTrades: [true],
      },
      {
        feed: "ti.cbbotsx",
        name: "TSX-CBBO",
        feedType: QMFeedType.BidAndOffer,
        exchanges: ["TSXC"],
        description: "CBBO TSX Consolidated Best Bid Offer",
        startDate: new Date("2022-06-20"),
        notes:
          "Consolidated Marketplace Feed for TSX Listings. NEO and CSE Listings require CDF feed.",
        internalCodes: [
          "RAWFEED_TICBBOTSX_RT",
          "RAWFEED_TICBBOTSX_DL",
          "SFTP_DAILY_FILE_TSX_CCQ_EOD",
        ],
      },
      {
        feed: "ti.cbbotsxv",
        name: "TSXV-CBBO",
        feedType: QMFeedType.BidAndOffer,
        exchanges: ["TSXVC"],
        description: "CBBO TSXV Consolidated Best Bid Offer",
        startDate: new Date("2022-06-20"),
        notes:
          "Consolidated Marketplace Feed for TSXV Listings. NEO and CSE Listings require CDF feed.",
        internalCodes: [
          "RAWFEED_TICBBOTSXV_RT",
          "RAWFEED_TICBBOTSXV_DL",
          "SFTP_DAILY_FILE_TSXV_CCQ_EOD",
        ],
      },
      {
        feed: "ti.clstsx",
        name: "TSX-CLS",
        feedType: QMFeedType.LastSale,
        exchanges: ["??"],
        description: "CLS TSX Consolidated Last Sale",
        startDate: new Date("2022-06-20"),
        notes:
          "Consolidated Marketplace Feed for TSX Listings. NEO and CSE Listings require CDF feed.",
        internalCodes: [
          "RAWFEED_TI.CLSTSX_RT",
          "RAWFEED_TICLSTSX_DL",
          "SFTP_DAILY_FILE_TSX_LS_EOD",
        ],
      },
      {
        feed: "ti.clstsxv",
        name: "TSXV-CLS",
        feedType: QMFeedType.LastSale,
        exchanges: ["??"],
        description: "CLS TSXV Consolidated Last Sale",
        startDate: new Date("2022-06-20"),
        notes:
          "Consolidated Marketplace Feed for TSXV Listings. NEO and CSE Listings require CDF feed.",
        internalCodes: [
          "RAWFEED_TICLSTSXV_RT",
          "RAWFEED_TICLSTSXV_DL",
          "SFTP_DAILY_FILE_TSXV_LS_EOD",
        ],
      },
    ].map((dataChannel) => [dataChannel.feed, dataChannel])
  );

// Other feeds that we are not currently using
const UNUSED_CANADIAN_DATA_CHANNELS: any[] = [
  {
    feed: "ca.tl1",
    name: "TSX-L1",
    exchanges: ["TSX"],
    description: "TSX L1 Quotes & Trades",
    notes: "",
    internalCodes: [],
  },
  {
    feed: "ca.tl1-quotes",
    name: "TSX-Q",
    exchanges: ["TSX"],
    description: "TSX L1 Quotes only",
    notes: "Intended to supplement CA.TL2",
    internalCodes: [],
  },
  {
    feed: "ca.cl1",
    name: "TSXV-L1",
    exchanges: ["CDX"],
    description: "TSXV L1 Quotes & Trades",
    notes: "",
    internalCodes: [],
  },
  {
    feed: "ca.cl1-quotes",
    name: "TSXV-Q",
    exchanges: ["CDX"],
    description: "TSXV L1 Quotes only",
    notes: "Intended to supplement CA.CL2",
    internalCodes: [],
  },
  {
    feed: "ca.al1",
    name: "ALPHA-L1",
    exchanges: ["ALPHA"],
    description: "ALPHA L1 Quotes & Trades",
    notes: "",
    internalCodes: [],
  },
  {
    feed: "ca.al1-quotes",
    name: "ALPHA-Q",
    exchanges: ["ALPHA"],
    description: "ALPHA L1 Quotes only",
    notes: "Intended to supplement CA.AL2",
    internalCodes: [],
  },
  {
    feed: "ca.tsxspindices",
    name: "S&P/TSX",
    exchanges: ["TSI"],
    description: "S&P indices from TSX",
    notes: "",
    internalCodes: [
      "RAWFEED_TSXSPINDICES_RT",
      "RAWFEED_TSXSPINDICES_DL",
      "SFTP_DAILY_FILE_TSXINDICES_EOD",
    ],
  },
  {
    feed: "hsvf-f1",
    name: "MXF-L1",
    exchanges: ["MX"],
    description: "Montreal Futures L1",
    notes: "Futures Trades and Top of Book Quotes (includes Level 1 data only)",
    internalCodes: ["RAWFEED_MXHSVF_RT", "RAWFEED_MXHSVF_DL"],
  },
  {
    feed: "hsvf-f2",
    name: "MXF-L2",
    exchanges: ["MX"],
    description: "Montreal Futures L2",
    notes:
      "Futures Trades and all market depth (includes Level 1 and Level 2 data)",
    internalCodes: ["RAWFEED_MXHSVF_RT", "RAWFEED_MXHSVF_DL"],
  },
  {
    feed: "hsvf-o1",
    name: "MXO-L1",
    exchanges: ["MX"],
    description: "Montreal Options L1",
    notes:
      "Equity/Futures Option Trades and Top of Book Quotes (includes Level 1 data only)",
    internalCodes: ["RAWFEED_MXHSVF_RT", "RAWFEED_MXHSVF_DL"],
  },
  {
    feed: "hsvf-o2",
    name: "MXO-L2",
    exchanges: ["MX"],
    description: "Montreal Options L2",
    notes:
      "Equity/Futures Option Trades and all market depth (includes Level 1 and Level 2 data)",
    internalCodes: ["RAWFEED_MXHSVF_RT", "RAWFEED_MXHSVF_DL"],
  },
];
