/**
 * Bid Ask Condition Indicator
 * 
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */
export enum QMBidAskConditionIndicator {
  /**
   * Automatic (and therefore protected)
   */
  AUTOMATIC = 'A',
  /**
   * Manual (and therefore unprotected)
   */
  MANUAL = 'M',
  /**
   * Unspecified or unknown (no Reg NMS status applies to this side of the MMQuote or unable to determine)
   */
  UNKNOWN = 'U',
  /**
   * Unsolicited (limit) order
   */
  UNSOLICITED_LIMIT_ORDER = 'l',
  /**
   * Unpriced
   */
  UNPRICED = 'u',
  /**
   * Wanted (bid or offer wanted)
   */
  WANTED = 'w'
}


