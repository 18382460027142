export const sentimentSymbolList = [
  "ARE",
  "AEM",
  "AC",
  "AGI",
  "AQN",
  "ATD",
  "AP.UN",
  "ALA",
  "AIF",
  "ARX",
  "ATZ",
  "AX.UN",
  "ACO",
  "ATA",
  "AUP",
  "ACB",
  "BTO",
  "BDGI",
  "BLDP",
  "BLUE",
  "BMO",
  "BNS",
  "ABX",
  "BHC",
  "BCE",
  "BIR",
  "BB",
  "BEI.UN",
  "BBD",
  "BLX",
  "BYD",
  "BAM",
  "BBU.UN",
  "BIP.UN",
  "BPY.UN",
  "BEP.UN",
  "DOO",
  "CAE",
  "CCO",
  "CF",
  "GOOS",
  "CAR.UN",
  "CM",
  "CNR",
  "CNQ",
  "CP",
  "CTC",
  "CU",
  "CWB",
  "CFP",
  "WEED",
  "CPX",
  "CS",
  "CJT",
  "CAS",
  "CCL",
  "CLS",
  "CVE",
  "CG",
  "GIB",
  "CSH.UN",
  "CHP.UN",
  "CIX",
  "CCA",
  "CIGI",
  "CUF.UN",
  "CSU",
  "CTS",
  "CJR",
  "CPG",
  "CRR.UN",
  "CRON",
  "CRT.UN",
  "DML",
  "DSG",
  "DCBO",
  "DOL",
  "DIR.UN",
  "D.UN",
  "DPM",
  "DND",
  "ECN",
  "ELD",
  "EFN",
  "EMA",
  "EMP",
  "ENB",
  "EDR",
  "ERF",
  "ENGH",
  "EQX",
  "EQB",
  "ERO",
  "EIF",
  "FFH",
  "FTT",
  "FCR.UN",
  "FR",
  "FM",
  "FSV",
  "FTS",
  "FVI",
  "FNV",
  "WN",
  "GFL",
  "GEI",
  "GIL",
  "GSY",
  "GRT.UN",
  "GWO",
  "HR.UN",
  "HCG",
  "HBM",
  "H",
  "IAG",
  "IMG",
  "IGM",
  "IMO",
  "INE",
  "IFC",
  "IPL",
  "IFP",
  "IIP.UN",
  "ITP",
  "IVN",
  "JWEL",
  "KNT",
  "KEY",
  "KMP.UN",
  "KXS",
  "K",
  "KL",
  "LIF",
  "LB",
  "LWRK",
  "LSPD",
  "LNR",
  "LAC",
  "L",
  "LUN",
  "MAG",
  "MG",
  "MFC",
  "MFI",
  "MRE",
  "MEG",
  "MX",
  "MRU",
  "MTY",
  "MTL",
  "NA",
  "NGD",
  "NXE",
  "NFI",
  "NPI",
  "NWH.UN",
  "NG",
  "NTR",
  "NVEI",
  "OGC",
  "ONEX",
  "OTEX",
  "OGI",
  "OR",
  "OSK",
  "PAAS",
  "PXT",
  "PKI",
  "PPL",
  "POW",
  "PSK",
  "PBH",
  "PVG",
  "PRMW",
  "QBR",
  "REAL",
  "QSR",
  "RCH",
  "REI.UN",
  "RBA",
  "RCI",
  "RY",
  "RUS",
  "SSL",
  "SAP",
  "SEA",
  "SJR",
  "SHOP",
  "SIA",
  "SVM",
  "SIL",
  "ZZZ",
  "SRU.UN",
  "SNC",
  "TOY",
  "SII",
  "SSRM",
  "STN",
  "STLC",
  "SJ",
  "SMU.UN",
  "SLF",
  "SU",
  "SOY",
  "SPB",
  "TRP",
  "TECK",
  "T",
  "TIXT",
  "TFII",
  "NWC",
  "TRI",
  "TLRY",
  "X",
  "TXG",
  "TIH",
  "TD",
  "TOU",
  "TA",
  "RNW",
  "TCL",
  "TCN",
  "TSU",
  "TRQ",
  "VET",
  "VFF",
  "WCN",
  "WELL",
  "WDO",
  "WFG",
  "WPRT",
  "WTE",
  "WPM",
  "WCP",
  "WPK",
  "WIR.UN",
  "WSP",
  "YRI",
].sort();
