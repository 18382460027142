/**
 * Limit Up Limit Down Price Band Indicators
 * ---
 * Limit Up is the maximum amount a price is permitted to increase during one trading day.
 * Limit Down is the maximum amount a price is permitted to decrease during one trading day.
 *
 * @see https://quotemediasupport.freshdesk.com/support/solutions/articles/13000022168--appendix-b-field-definitions-
 */

export enum QMLimitUpLimitDownPriceBandIndicator {
  NOT_APPLICABLE = 0,
  OPENING_UPDATE = 1,
  INTRADAY_UPDATE = 2,
  RESTATED_VALUE = 3,
  /**
   * Suspended During Trading Halt or Trading Pause
   */
  SUSPENDED_DURING_HALT = 4,
  REOPENING_UPDATE = 5,
  OUTSIDE_PRICE_BAND_RULE_HOURS = 6
}
